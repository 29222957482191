import React from "react";
import CloseIcon from '@mui/icons-material/Close';
import LineChart from "../../Shared/Charts/LineChart";
import BarChart from "../../Shared/Charts/BarChart";
import WaterProofView from "../../Shared/Canvas/WaterProofView";

const DataChart: React.FC<any> = (props) => {

   return (
      <div className="DataChart">
         <div className="title-popup">
            <div className="cursor content">Dữ liệu biểu đồ<span style={{ color: '#2F3193' }}></span></div>
            <div className="btn-close" onClick={e => props.setToggleChart(!props.toggleChart)}><CloseIcon /></div>
         </div>
         <div className="content-popup">
         {props.listChart.map((charts, i) => {
            return <div key={i} className="item">
               {charts.map((c, index) => {
                  if (c.group.metric_codes?.includes("WP")) return <WaterProofView key={index} box_id={c.box_id} />
                  if (c.type === 'line') return <LineChart key={index} data={c.data} options={c.options} />;
                  if (c.type === 'groupbar') return <BarChart key={index} data={c.data} options={c.options} />
                  else return null;
               })}
            </div>
         })}
         </div>
      </div>
   )
};

export default DataChart;
