export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const CAMERA_URL = process.env.REACT_APP_CAMERA_URL; 

export const SESSIONSTORAGE = {
    TOKEN: 'token',
    USER: 'user',
    ID: 'id',
    LOCATION: 'location',
    BOXGROUPID: 'box_group_id'
}

export const TYPE_BOX = {
    WA: { label: 'Đo mực nước', code: 'WA' },
    WP: { label: 'Đo thấm', code: 'WP' },
    RA: { label: 'Đo mưa', code: 'RA' },
    DR: { label: 'Đo độ mở cống', code: 'DR' },
    SA: { label: 'Đo độ mặn', code: 'SA' },
}