import React, { useState } from "react";
import PanoramaIcon from '@mui/icons-material/Panorama';
import CheckBoxMetric from "../CheckBoxMetric/CheckBoxMetric";
import LayersIcon from '@mui/icons-material/Layers';
import Help from "../Help/Help";
import LayerMap from "../LayerMap/LayerMap";
import ListAltIcon from '@mui/icons-material/ListAlt';
import MetricIcon from "../../../Shared/Components/IconToolBar/MetricIcon";
import LayerIcon from "../../../Shared/Components/IconToolBar/LayerIcon";
import HelpIcon from "../../../Shared/Components/IconToolBar/HelpIcon";

const ToolBar: React.FC<any> = (props) => {
    const [toggleMenu, setToggleMenu] = useState(0);
    const toggle = (e, type) => {
        if(toggleMenu !== type) {
            setToggleMenu(type)
        } else {
            setToggleMenu(0)
        }
    }

    // const handleDraw = () => {
    //     const map = useMap();
    //     (L.control as any).ruler().addTo(map);
    // }

    return (
        <div className="ToolBar" >
            <div className={"toolbar-display " + (toggleMenu !== 0 ? "toggleToolBar" : "")} >
                {toggleMenu === 1 && <Help /> }
                {toggleMenu === 2 && <CheckBoxMetric checkedBoxMetrics={props.checkedBoxMetrics} setCheckedBoxMetrics={props.setCheckedBoxMetrics}/> }
                {toggleMenu === 3 && <LayerMap layer={props.layer} setLayer={props.setLayer} /> }
                {toggleMenu === 4 && <div></div> }
            </div>
            <div className="content">
                <div className={toggleMenu === 2 ? "icon-item bgr-active" : "icon-item"} onClick={(e) => toggle(e, 2)}>
                    <MetricIcon color={toggleMenu === 2 ? '#FFF' : "#4285F4"} />
                </div>
                <div className={toggleMenu === 3 ? "icon-item bgr-active" : "icon-item"} onClick={(e) => toggle(e, 3)}>
                    <LayerIcon color={toggleMenu === 3 ? '#FFF' : "#4285F4"} />
                </div>
                <div className={toggleMenu === 1 ? "icon-item bgr-active" : "icon-item"} onClick={(e) => toggle(e, 1)} >
                    <HelpIcon color={toggleMenu === 1 ? '#FFF' : "#4285F4"} />
                </div>
                {/* <div className={toggleMenu === 4 ? "icon-item bgr-active" : "icon-item"} onClick={(e) => toggle(e, 4)}>
                    <AddPhotoAlternateIcon />
                </div> */}
            </div>
        </div>
    )
};

export default ToolBar;
