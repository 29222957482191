import React from "react";
import { Popup } from "react-leaflet";

const BoxPopup: React.FC<any> = (props) => {

    return <>
        <Popup>
            <div className="title" >{props.box.name}</div>
            <div className="content">
                {props.box?.metrics?.map((m, i) => {
                    return <div key={i} className="item">
                        <span className="item-title">{m.name}: </span>
                        {props.record && Object.keys(props.record).some(key => key === m.code) ?
                            props.record[m.code] : "0"
                        }
                    </div>
                })}
            </div>
        </Popup>
    </>
};

export default BoxPopup;
