import React from "react";
import { ReactComponent as BoxRain } from "../../../Shared/Components/Icon/Vector.svg";
import { ReactComponent as BoxPermeability } from "../../../Shared/Components/Icon/DoTham.svg";
import { ReactComponent as BoxWater } from "../../../Shared/Components/Icon//MucNuoc.svg";
import { ReactComponent as BoxDrain} from "../../../Shared/Components/Icon/DoMoCong.svg";
import { ReactComponent as BoxSalinity } from "../../../Shared/Components/Icon/DoMan.svg";
import RainWarning from "../../../Shared/Components/Icon/RainWarning";
import Warning from "../../../Shared/Components/Icon/Warning";
import { ReactComponent as Warning0 } from "../../../Shared/Components/Icon/warning0.svg";
import { ReactComponent as Warning1 } from "../../../Shared/Components/Icon/warning1.svg";
import { ReactComponent as Warning2 } from "../../../Shared/Components/Icon/warning2.svg";
import { ReactComponent as Warning3 } from "../../../Shared/Components/Icon/warning3.svg";
import { ReactComponent as Warning4 } from "../../../Shared/Components/Icon/warning4.svg";

const Help: React.FC<any> = () => {

  return (
    <div className="Help">
        <h4 className="help-title">Chú giải</h4>
        <div  className="box">
            <div className="title">Trạm đo</div>
            <div className="item">
                <div className="icon"><BoxRain /></div>
                <div className="note">Trạm đo mưa</div>
            </div>
            <div className="item">
                <div className="icon"><BoxPermeability /></div>
                <div className="note">Trạm đo thấm</div>
            </div>
            <div className="item">
                <div className="icon"><BoxWater /></div>
                <div className="note">Trạm đo mực nước</div>
            </div>
            <div className="item">
                <div className="icon"><BoxDrain /></div>
                <div className="note">Trạm đo độ mở cống</div>
            </div>
            <div className="item">
                <div className="icon"><BoxSalinity /></div>
                <div className="note">Trạm đo độ mặn</div>
            </div>
        </div>
        <div className="box">
            <div className="title">Cảnh báo lượng mưa</div>
            <div className="item">
                <div className="icon"><RainWarning /></div>
                <div className="note">Dưới 50 mm</div>
            </div>
            <div className="item">
                <div className="icon"><RainWarning color="#BAEB30" /></div>
                <div className="note">Từ 50 - 100 mm</div>
            </div>
            <div className="item">
                <div className="icon"><RainWarning color="#FB610A" /></div>
                <div className="note">Từ 100 - 200 mm</div>
            </div>
            <div className="item">
                <div className="icon"><RainWarning color="#8D1DB5" /></div>
                <div className="note">&gt; 200 mm</div>
            </div>
        </div>
        <div  className="box">
            <div className="title">Cảnh báo an toàn hồ đập</div>
            <div className="item">
                <div className="icon"><Warning /></div>
                <div className="note">Mức an toàn</div>
            </div>
            <div className="item">
                <div className="icon"><Warning color="#FB610A" /></div>
                <div className="note">Dưới mực nước chết</div>
            </div>
            <div className="item">
                <div className="icon"><Warning color="#ED1D24" /></div>
                <div className="note">Lớn hơn mực nước dâng bình thường</div>
            </div>
            <div className="item">
                <div className="icon"  style={{ width: '40px'}}><Warning color="#8D1DB5" /></div>
                <div className="note">Vận hành sai quy trình hoặc lớn hơn mực nước dâng gia cường</div>
            </div>
        </div>
        <div  className="box">
            <div className="title">Cảnh báo an toàn hồ đập</div>
            <div className="item">
                <div className="icon"><Warning0 /></div>
                <div className="note">Không báo động</div>
            </div>
            <div className="item">
                <div className="icon"><Warning1 /></div>
                <div className="note">Báo động mức 1</div>
            </div>
            <div className="item">
                <div className="icon"><Warning2 /></div>
                <div className="note">Báo động mức 2</div>
            </div>
            <div className="item">
                <div className="icon"><Warning3 /></div>
                <div className="note">Báo động mức 3</div>
            </div>
            <div className="item">
                <div className="icon"><Warning4 /></div>
                <div className="note">Báo động mức 4</div>
            </div>
        </div>
    </div>
  )
};

export default Help;
