import React from "react";

const CameraIcon: React.FC<any> = (props) => {
    const color = props.color ? props.color : "#4285F4"
    const fillColor = props.fillColor ? props.fillColor : "#4285F4"
    return (
        <svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="27.5" cy="27.5" r="26.75" fill={fillColor} stroke={color} stroke-width="1.5"/>
            <path d="M23.525 15.3083L21.2167 17.675H12.2417C12.0458 17.675 11.8518 17.7136 11.6708 17.7885C11.4899 17.8635 11.3254 17.9734 11.1869 18.1119C11.0484 18.2504 10.9385 18.4148 10.8635 18.5958C10.7886 18.7768 10.75 18.9708 10.75 19.1667V38.5167C10.75 38.913 10.9069 39.2933 11.1864 39.5744C11.4659 39.8554 11.8453 40.0144 12.2417 40.0167H41.7583C42.1547 40.0144 42.5341 39.8554 42.8136 39.5744C43.0931 39.2933 43.25 38.913 43.25 38.5167V19.1667C43.25 18.9708 43.2114 18.7768 43.1365 18.5958C43.0615 18.4148 42.9516 18.2504 42.8131 18.1119C42.6746 17.9734 42.5101 17.8635 42.3292 17.7885C42.1482 17.7136 41.9542 17.675 41.7583 17.675H32.7833L30.475 15.3083H23.525ZM39.3 20.0917C39.7067 20.0917 40.0967 20.2532 40.3842 20.5408C40.6718 20.8283 40.8333 21.2183 40.8333 21.625C40.8333 22.0317 40.6718 22.4217 40.3842 22.7092C40.0967 22.9968 39.7067 23.1583 39.3 23.1583C38.8933 23.1583 38.5033 22.9968 38.2158 22.7092C37.9282 22.4217 37.7667 22.0317 37.7667 21.625C37.7667 21.2183 37.9282 20.8283 38.2158 20.5408C38.5033 20.2532 38.8933 20.0917 39.3 20.0917ZM27 22.7583C28.4026 22.7583 29.7737 23.1742 30.9399 23.9535C32.1061 24.7327 33.0151 25.8403 33.5518 27.1361C34.0886 28.432 34.229 29.8579 33.9554 31.2335C33.6818 32.6092 33.0064 33.8728 32.0146 34.8646C31.0228 35.8563 29.7592 36.5318 28.3835 36.8054C27.0079 37.079 25.582 36.9386 24.2861 36.4018C22.9903 35.8651 21.8827 34.9561 21.1035 33.7899C20.3243 32.6237 19.9083 31.2526 19.9083 29.85C19.9083 27.9692 20.6555 26.1654 21.9854 24.8354C23.3154 23.5055 25.1192 22.7583 27 22.7583V22.7583Z" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

    )
};

export default CameraIcon;
