import React from "react";

const LayerIcon: React.FC<any> = (props) => {
    const color = props.color ? props.color : "#4285F4"
    return (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.04 10.53H10.84V12.435H10.04V10.53Z" fill={color} />
            <path d="M5.04003 5.53H5.84003V7.435H5.04003V5.53Z" fill={color} />
            <path d="M5.04003 8.565H5.84003V10.44H5.04003V8.565Z" fill={color} />
            <path d="M15 6.61V13.375L10.84 14.675V13.53H10.04V14.53L5.84001 12.375V11.53H5.04001V12.39L1.00001 14.055V4.895L5.04001 3.23V4.405H5.84001V3.235L10.04 5.385V6.435H10.84V5.5L11.13 5.41C10.8984 5.13482 10.7065 4.8285 10.56 4.5L5.73001 2.055C5.66419 2.02454 5.59253 2.00877 5.52001 2.00877C5.44748 2.00877 5.37582 2.02454 5.31001 2.055L0.310006 4.1C0.217909 4.13784 0.139204 4.20231 0.0839749 4.28515C0.0287458 4.36799 -0.000492387 4.46544 6.27379e-06 4.565V14.805C0.000414213 14.8869 0.0209305 14.9674 0.0597518 15.0395C0.0985732 15.1116 0.154511 15.1731 0.22265 15.2185C0.290789 15.264 0.369042 15.292 0.450533 15.3001C0.532024 15.3082 0.614257 15.2961 0.690006 15.265L5.50001 13.265L10.295 15.725C10.3662 15.7615 10.445 15.7803 10.525 15.78C10.5745 15.79 10.6255 15.79 10.675 15.78L15.675 14.22C15.7804 14.187 15.8718 14.12 15.9351 14.0296C15.9985 13.9392 16.0301 13.8303 16.025 13.72V6.155C15.7079 6.35686 15.3624 6.51022 15 6.61Z" fill={color} />
            <path d="M14 5.5C15.3807 5.5 16.5 4.38071 16.5 3C16.5 1.61929 15.3807 0.5 14 0.5C12.6193 0.5 11.5 1.61929 11.5 3C11.5 4.38071 12.6193 5.5 14 5.5Z" fill={color} />
        </svg>

    )
};

export default LayerIcon;
