export const INFO_GROUP = [
    {
        label: "Vùng miền",
        key: "region",
    },
    {
        label: "Tỉnh/Thành phố",
        key: "city",
    },
    {
        label: "Tên công trình",
        key: "name"
    },
    {
        label: "Cấp công trình",
        key: "level",
    },
    {
        label: "Lưu vực",
        key: "basin",
    },
    {
        label: "Đơn vị quản lý",
        key: "manage",
    },
    {   
        label: "Nhiệm vụ công trình",
        key: "mission"
    },
    {
        label: "Tần suất tưới thiết kế(ha)",
        key: "watering",
    },
    {
        label: "Tần suất lũ thiết kế (%)",
        key: "frequency_flood",
    },
    {
        label: "Tần suất lũ kiểm tra (%)",
        key: "frequency_flood_origin",
    },
    {
        label: "Diện tích(km2)",
        key: "area",
    },
    {
        label: "Mực nước chết",
        key: "water_rise_die",
    },
    {
        label: "MN dâng gia cường(m)",
        key: "water_rise_hight",
    },
    {
        label: "MN dâng bình thường(m)",
        key: "water_rise_nomarl",
    },
    {
        label: "Dung tích hồ ứng với MNC",
        key: "capacity_rise_die",
    },
    {
        label: "Dung tích hồ ứng với MNDBT",
        key: "capacity_rise_normal",
    },
    {
        label: "Dung tích hồ ứng với MNDGC",
        key: "capacity_rise_hight",
    },
    {
        label: "Diện tích hồ ứng với MNC(ha)",
        key: "area_rise_die",
    },
    {
        label: "Diện tích hồ ứng với MNDBT(ha)",
        key: "area_rise_normal",
    },
    {
        label: "Diện tích hồ ứng với MNDGC(ha)",
        key: "area_rise_hight",
    },
    {
        label: "Kết cấu đập",
        key: "structure",
    },
    {
        label: "Cao trình đập",
        key: "elevation",
    },
    {
        label: "Chiều cao đập lớn nhất(m)",
        key: "height",
    },
    {
        label: "Chiều dài đập(m)",
        key: "longs",
    },
    {
        label: "Bề rộng mặt đập(m)",
        key: "width",
    },
    {
        label: "Hệ số mái thượng lưu",
        key: "roof_coefficient_up",
    },
    {
        label: "Hệ số mái hạ lưu",
        key: "roof_coefficient_down",
    },
    {
        label: "Năm xây dựng",
        key: "year_built",
    },
    {
        label: "Năm hoàn thành",
        key: "year_completed",
    },

]