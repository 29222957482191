import React from "react";

const BoxPermeability: React.FC<any> = (props) => {
    const color = props.color ? props.color : "#4285F4"
    return (
        <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="3.0625" cy="8.5625" r="2.1875" fill={color} stroke="white" stroke-width="0.25" />
            <circle cx="2.71875" cy="13.0312" r="1.84375" fill={color} stroke="white" stroke-width="0.25" />
            <circle cx="2.375" cy="16.8125" r="1.5" fill={color} stroke="white" stroke-width="0.25" />
            <circle cx="2.03125" cy="19.9062" r="1.15625" fill={color} stroke="white" stroke-width="0.25" />
            <circle cx="1.6875" cy="22.3125" r="0.8125" fill={color} stroke="white" stroke-width="0.25" />
            <circle cx="16.125" cy="22.3125" r="0.8125" fill={color} stroke="white" stroke-width="0.25" />
            <circle cx="14.0625" cy="22.3125" r="0.8125" fill={color} stroke="white" stroke-width="0.25" />
            <circle cx="12" cy="22.3125" r="0.8125" fill={color} stroke="white" stroke-width="0.25" />
            <circle cx="9.9375" cy="22.3125" r="0.8125" fill={color} stroke="white" stroke-width="0.25" />
            <circle cx="7.875" cy="22.3125" r="0.8125" fill={color} stroke="white" stroke-width="0.25" />
            <circle cx="5.8125" cy="22.3125" r="0.8125" fill={color} stroke="white" stroke-width="0.25" />
            <circle cx="3.75" cy="22.3125" r="0.8125" fill={color} stroke="white" stroke-width="0.25" />
            <circle cx="15.7812" cy="19.9062" r="1.15625" fill={color} stroke="white" stroke-width="0.25" />
            <circle cx="13.0312" cy="19.9062" r="1.15625" fill={color} stroke="white" stroke-width="0.25" />
            <circle cx="10.2812" cy="19.9062" r="1.15625" fill={color} stroke="white" stroke-width="0.25" />
            <circle cx="7.53125" cy="19.9062" r="1.15625" fill={color} stroke="white" stroke-width="0.25" />
            <circle cx="4.78125" cy="19.9062" r="1.15625" fill={color} stroke="white" stroke-width="0.25" />
            <circle cx="15.4375" cy="16.8125" r="1.5" fill={color} stroke="white" stroke-width="0.25" />
            <circle cx="12" cy="16.8125" r="1.5" fill={color} stroke="white" stroke-width="0.25" />
            <circle cx="9.25" cy="16.8125" r="1.5" fill={color} stroke="white" stroke-width="0.25" />
            <circle cx="5.8125" cy="16.8125" r="1.5" fill={color} stroke="white" stroke-width="0.25" />
            <circle cx="15.0938" cy="13.0312" r="1.84375" fill={color} stroke="white" stroke-width="0.25" />
            <circle cx="10.9688" cy="13.0312" r="1.84375" fill={color} stroke="white" stroke-width="0.25" />
            <circle cx="6.84375" cy="13.0312" r="1.84375" fill={color} stroke="white" stroke-width="0.25" />
            <circle cx="8.5625" cy="8.5625" r="2.1875" fill={color} stroke="white" stroke-width="0.25" />
            <circle cx="14.75" cy="8.5625" r="2.1875" fill={color} stroke="white" stroke-width="0.25" />
            <circle cx="14.4062" cy="3.40625" r="2.53125" fill={color} stroke="white" stroke-width="0.25" />
            <circle cx="3.40625" cy="3.40625" r="2.53125" fill={color} stroke="white" stroke-width="0.25" />
            <circle cx="8.90625" cy="3.40625" r="2.53125" fill={color} stroke="white" stroke-width="0.25" />
        </svg>
    )
};

export default BoxPermeability;