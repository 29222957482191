import React from "react";


const LayerMap: React.FC<any> = (props) => {
    const selectLayer = (e, type) => {
        props.setLayer(type)
    }

    return (
        <div className="LayerMap">
            <div style={{ backgroundImage: 'url(/map-img/map-default.png)' }} className={props.layer === 'default' ? "layer-item select-layer" : "layer-item"} onClick={(e) => selectLayer(e, 'default')} >
                <div className="layer-item-title">Map mặc định</div>
            </div>
            <div style={{ backgroundImage: 'url(/map-img/map-black-white.png)' }} className={props.layer === 'black-white' ? "layer-item select-layer" : "layer-item"} onClick={(e) => selectLayer(e, 'black-white')} >
                <div className="layer-item-title">Map tối màu</div>
            </div>
            <div style={{ backgroundImage: 'url(/map-img/map-topo.png)' }} className={props.layer === 'topo' ? "layer-item select-layer" : "layer-item"} onClick={(e) => selectLayer(e, 'topo')} >
                <div className="layer-item-title">Open Topo</div>
            </div>
            <div style={{ backgroundImage: 'url(/map-img/map-gg.png)' }} className={props.layer === 'gg' ? "layer-item select-layer" : "layer-item"} onClick={(e) => selectLayer(e, 'gg')} >
                <div className="layer-item-title">Google Hybrid</div>
            </div>
            <div style={{ backgroundImage: 'url(/map-img/map-world-topo.png)' }} className={props.layer === 'world-topo' ? "layer-item select-layer" : "layer-item"} onClick={(e) => selectLayer(e, 'world-topo')} >
                <div className="layer-item-title">World Topo</div>
            </div>
            <div style={{ backgroundImage: 'url(/map-img/map-world-street.png)' }} className={props.layer === 'world-street' ? "layer-item select-layer" : "layer-item"} onClick={(e) => selectLayer(e, 'world-street')} >
                <div className="layer-item-title">World Street</div>
            </div>
            <div style={{ backgroundImage: 'url(/map-img/map-world-imagery.png)' }} className={props.layer === 'world-imagery' ? "layer-item select-layer" : "layer-item"} onClick={(e) => selectLayer(e, 'world-imagery')} >
                <div className="layer-item-title">World Imagery</div>
            </div>
        </div>
    )
};

export default LayerMap;
