import React from "react";

const ChartIcon: React.FC<any> = (props) => {
    const color = props.color ? props.color : "#4285F4"
    const fillColor = props.fillColor ? props.fillColor : "#4285F4"
    return (
        <svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="27.5" cy="27.5" r="26.75" fill={fillColor} stroke={color} stroke-width="1.5"/>
            <path d="M26.4783 27.8963L28.5383 14.4213C27.8571 14.314 27.169 14.2567 26.4795 14.25C18.9408 14.25 12.832 20.36 12.832 27.8963C12.832 35.4338 18.942 41.5425 26.4783 41.5425C34.0145 41.5425 40.1245 35.4325 40.1245 27.8963C40.1245 27.5763 40.0995 27.2625 40.077 26.9463L26.477 27.8963H26.4783ZM31.2958 11.9088L29.2358 25.3838L42.8333 24.4338C42.617 21.3466 41.357 18.4248 39.2602 16.1487C37.1635 13.8725 34.3548 12.3773 31.2958 11.9088Z" fill={color}/>
        </svg>


    )
};

export default ChartIcon;
