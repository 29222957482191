import { useEffect } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";
import "./leaflet-ruler";
import "./leaflet-ruler.css";
import { DOI_ONG_TUONG, SONG_MAY, HO_DA_TON, SONG_LUY } from "./config_polygon.js";

export default function LeafletRuler() {
  const map = useMap();
  // const [polygon, setPolygon] = useState([]);
  
  useEffect(() => {
    if (!map) return;
    L.control.ruler().addTo(map);
    [DOI_ONG_TUONG, SONG_MAY, HO_DA_TON, SONG_LUY].forEach(area => {
      L.polyline(area, { color: "red" }).addTo(map);
    })
  }, [map]);

  // map.on("click", function(e){
  //   const mp = new L.Marker([e.latlng.lat, e.latlng.lng]);
  //   console.log(mp._latlng)
  //   polygon.push(mp._latlng);
  //   L.polyline(polygon, { color: "red" }).addTo(map);
  //   setPolygon(polygon);
  // });

  return null;
}