import React from "react";

const BoxRain: React.FC<any> = (props) => {
    const color = props.color ? props.color : "#4285F4"
    return (
        <svg width="19" height="27" viewBox="0 0 19 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.38889 26.125H2.51389V26V2.38889C2.51389 1.93632 2.14701 1.56944 1.69444 1.56944C1.24188 1.56944 0.875 1.93632 0.875 2.38889V26V26.125H1H2.38889Z" fill={color} stroke="white" stroke-width="0.25" />
            <path d="M18.3611 26.125H18.4861V26V2.38889C18.4861 1.93632 18.1192 1.56944 17.6666 1.56944C17.214 1.56944 16.8472 1.93632 16.8472 2.38889V26V26.125H16.9722H18.3611Z" fill={color} stroke="white" stroke-width="0.25" />
            <rect x="0.875" y="24.4861" width="17.6111" height="1.63889" fill={color} stroke="white" stroke-width="0.25" />
            <rect x="2.95801" y="5.73611" width="13.4444" height="12.75" rx="2.125" fill={color} stroke="white" stroke-width="0.25" />
            <rect x="5.73633" y="0.875" width="0.944444" height="5.11111" fill={color} stroke="white" stroke-width="0.25" />
            <rect x="12.6807" y="0.875" width="0.944444" height="5.11111" fill={color} stroke="white" stroke-width="0.25" />
        </svg>
    )
};

export default BoxRain;