import React from "react";
import { Marker } from "react-leaflet";
import { iconPlace, iconWA, iconSA, iconRA, iconWP, iconDR } from "./icon";
import { TYPE_BOX } from "../../../Shared/Constances/const";
import PopupBox from "./BoxPopup";
import SensorPopup from "./SensorPopup";


const MarkerMap: React.FC<any> = (props) => {

    return <>
        <Marker key={props.index} position={[props.box.location.lat, props.box.location.lng]}
            icon={props.box.type === TYPE_BOX.WA.code ? iconWA : props.box.type === TYPE_BOX.WP.code ? iconWP : props.box.type === TYPE_BOX.RA.code ? iconRA
                : props.box.type === TYPE_BOX.DR.code ? iconDR : props.box.type === TYPE_BOX.SA.code ? iconSA : iconPlace}
            eventHandlers={{
                click: e => props.selectBox(e, props.box.id),
            }}>
            <PopupBox box={props.box} record={props.record} />
        </Marker>
        {props.box.metrics.map((metric, i) => {
            if (metric.lat && metric.lng) {              
                return <Marker key={i} position={[metric.lat, metric.lng]}
                        icon={metric.metric.type === TYPE_BOX.WA.code ? iconWA : metric.metric.type === TYPE_BOX.WP.code ? iconWP : metric.metric.type === TYPE_BOX.RA.code ? iconRA
                            : metric.metric.type === TYPE_BOX.DR.code ? iconDR : metric.metric.type === TYPE_BOX.SA.code ? iconSA : iconPlace}>
                    <SensorPopup box={props.box} metric={metric} record={props.record} />
                </Marker>
            } else return null
        })}
    </>
};

export default MarkerMap;
