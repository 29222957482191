import React from 'react';
import Grid from '@mui/material/Grid';
import CameraIcon from "../../../Shared/Components/IconHelp/CameraIcon";
import ChartIcon from "../../../Shared/Components/IconHelp/ChartIcon";
// import { ReactComponent as SkyIcon } from "../../../Shared/Components/Icon/SkyIcon.svg";
import DataIcon from "../../../Shared/Components/IconHelp/DataIcon";
import { INFO_GROUP } from "../GroupInfo/info";

const GroupInfo: React.FC<any> = (props) => {

   const openTable = () => {
      props.setToggleTable(true);
      props.setToggleChart(false);
      props.setToggleCamera(false);
   }

   const openChart = () => {
      props.setToggleChart(true);
      props.setToggleTable(false);
      props.setToggleCamera(false);
   }

   const openCamera = () => {
      props.setToggleCamera(true);
      props.setToggleTable(false);
      props.setToggleChart(false);
   }

   return (
      <div className="GroupInfo">
         <div className="info">
            <h4 className="title">Thông tin hồ chứa</h4>
            <div className="item">
            {props.group.note && INFO_GROUP.map((h, i) => {
               return (
                  <Grid key={i} container spacing={2} className="info-item">
                     <Grid item xs={6}>
                        {h.label}
                     </Grid>
                     <Grid item xs={6} className="value">
                        {h.key === "name" && <b>{props.group?.name}</b>}
                        {h.key !== 'mission' ? <b>{props.group?.note[h.key]}</b> 
                        :  <select id="mission">
                              {props.group?.note?.mission.map((m, i) => {
                                 return <option key={i} >{m.name}</option>
                              })}
                           </select>
                        }
                     </Grid>
                  </Grid>
               )
            })}
            </div>
         </div>
         <div className="options">
            <h4 className="title">Hỗ trợ vận hành đập</h4>
            <div className="box">
               <div className={props.toggleTable ? "item selected-data item-data" : "item item-data"} onClick={openTable}>
                  <div className="icon"><DataIcon color={props.toggleTable ? '#FFF' : "#4285F4"} fillColor={props.toggleTable ? '#4285F4' : "#FFF"} /></div>
                  <div className="note"><b>Quan trắc tổng hợp</b></div>
               </div>
               <div className={props.toggleCamera ? "item selected-data" : "item"} onClick={openCamera}>
                  <div className="icon"><CameraIcon  color={props.toggleCamera ? '#FFF' : "#4285F4"} fillColor={props.toggleCamera ? '#4285F4' : "#FFF"}  /></div>
                  <div className="note"><b>Camera</b></div>
               </div>
               <div className={props.toggleChart ? " item selected-data" : "item"} onClick={openChart}>
                  <div className="icon"><ChartIcon  color={props.toggleChart ? '#FFF' : "#4285F4"} fillColor={props.toggleChart ? '#4285F4' : "#FFF"}  /></div>
                  <div className="note"><b>Hỗ trợ vận hành</b></div>
               </div>
               {/* <div className="item">
                  <div className="icon"><SkyIcon /></div>
                  <div className="note"><b>Hỗ trợ</b></div>
               </div> */}
            </div>
         </div>
      </div>
   )
};

export default GroupInfo;
