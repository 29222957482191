import React, { useState, useRef } from "react";
import Draggable from "react-draggable";

export default function DraggableMe(props) {
  const nodeRef = useRef(null);

  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [Opacity, setOpacity] = useState(false);

  const trackPos = (e, data) => {
    setPosition({ x: data.x, y: data.y });
  };



  const handleStart = (e) => {
    const cl = e.target.className;
    if(!cl || typeof cl !== 'string') return false;
    if(!cl.includes('cursor')) return false;
    setOpacity(true);
  };
  const handleEnd = (e) => {
    setOpacity(false);
  };


  return (
    <div className="DraggableMe">
      <Draggable
        nodeRef={nodeRef}
        defaultPosition={{ x: position.x, y: position.y }}
        onDrag={(e, data) => trackPos(e, data)}
        onStart={handleStart}
        onStop={handleEnd}
      >
        <div
          ref={nodeRef}
          className="box"
          style={{ opacity: Opacity ? "0.6" : "1", background: "gray" }}
        >
          {props.children}
        </div>
      </Draggable>
    </div>
  );
}
