import { LineChart as LC } from '@carbon/charts';
import { LineChartOptions } from '@carbon/charts/interfaces';
import BaseChart from './BaseChart';
export default class LineChart extends BaseChart<LineChartOptions> {
    chartRef?: HTMLDivElement;
    props!: any;
    chart!: LC;

    componentDidMount() {
        if (!this.props.data) return;
        if (!this.props.options) return;

        this.chart = new LC(this.chartRef!, {
            data: this.props.data as any,
            options: this.props.options as any,
        });
    }

    render() {
        return (
            <div className="item-chart">
                <div ref={(chartRef) => (this.chartRef = chartRef!)} className="chart-holder"></div>
            </div>
        );
    }
}