import React, { useState } from 'react';
import { List, ListSubheader } from '@mui/material';
import { ReactComponent as MenuIcon } from "../../../Shared/Components/Icon/menu.svg";
import { ReactComponent as CloseIcon } from "../../../Shared/Components/Icon/x.svg";
import { ReactComponent as NextIcon } from "../../../Shared/Components/Icon/next.svg";
import { ReactComponent as PreviousIcon } from "../../../Shared/Components/Icon/previous.svg";
import GroupInfo from '../GroupInfo/GroupInfo';
import ListGroup from '../ListGroup/ListGroup';
import { METRIC_GROUPS } from '../Chart/data_chart';
import logo from "../../../Shared/Components/Icon/logo.png"

const Menu: React.FC<any> = (props) => {
    const [toggleMenu, setToggleMenu] = useState(false)
 
    const onSelectGroup = (groupId) => {
        let result = props.listGroup.find(g => g.id === groupId);
        props.setGroup(result);
        props.setBox(result.boxs[0])
        props.setTypeMenu(2);
        props.setZoom(result.zoom)
    }
    const onChangeMenu = (e, type) => {
        if (!props.group) return;
        if (type === 'previous' && props.typeMenu !== 1) {
            props.setTypeMenu(props.typeMenu - 1);
            return;
        }
        if (type === 'next' && props.typeMenu !== 2) {
            props.setTypeMenu(props.typeMenu + 1);
            return;
        }
    }

    const handleChange = (e) => {
        const result = props.listGroup?.find(group => group.id === e.target.value);
        props.setGroup(result);
        props.setBox(result.boxs[0]);
    }

    return (
        <div className="Menu" >
            <div className="MenuIcon" onClick={e => setToggleMenu(!toggleMenu)} style={{ left: toggleMenu ? '-30vw' : '0' }} ><MenuIcon /></div>
            <List
                style={{ left: toggleMenu ? '0' : '-30vw' }}
                sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                component="nav"
                aria-labelledby="Danh Sách Công Trình"
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                        <div className="logo">
                            <div className="img-logo">
                                <img alt="" src={logo} />
                            </div>
                            <div className="spacing"></div>
                            <div className="icon-close" onClick={e => setToggleMenu(!toggleMenu)}>
                                <CloseIcon />
                            </div>
                        </div>
                        <div className="title-menu">
                            <div onClick={e => onChangeMenu(e, 'previous')} className="previous">{props.typeMenu !== 1 && <PreviousIcon />}</div>
                            <div className="center">
                                {props.typeMenu === 1 ? 'CSDL NGÀNH THỦY LỢI' : 
                                    props.typeMenu === 3 ? 'BIỂU ĐỒ DỮ LIỆU' 
                                    : 
                                    <div className="group-select">
                                        <label>CÔNG TRÌNH</label>
                                        <select id="group" value={props.group.id} onChange={handleChange}>
                                            {props.listGroup?.map((group, i) => {
                                                return <option key={i} value={group.id}>{group.name}</option>
                                            })}
                                        </select>
                                    </div>
                                }</div>
                            {/* <div onClick={e => onChangeMenu(e, 'next')} className="next">{props.typeMenu !== 2 && <NextIcon />}</div> */}
                        </div>
                    </ListSubheader>
                }
            >
                {props.typeMenu === 1 && <ListGroup listZone={props.listZone} onSelectGroup={onSelectGroup} listGroup={props.listGroup} setGroup={props.setGroup} setZoneId={props.setZoneId} />}
                {props.typeMenu === 2 && <GroupInfo group={props.group} setGroup={props.setGroup} listGroup={props.listGroup}
                    setToggleTable={props.setToggleTable} setToggleCamera={props.setToggleCamera} toggleTable={props.toggleTable} toggleCamera={props.toggleCamera}
                    setBox={props.setBox} setToggleChart={props.setToggleChart} toggleChart={props.toggleChart}
                />}
                {props.typeMenu === 3 && <>{METRIC_GROUPS.map((g, i) => {
                    // let report = [];
                    // Object.keys(props.dataChart).forEach(code => {
                    //     if (g.metric_codes.includes(code)) {
                    //         report.push(props.dataChart[code]);
                    //     }
                    // })
                    // if (report.length > 0) {
                    //     return <LineChart key={i} title={g.label} report={report} />
                    // } else {
                    //     return null
                    // }
                    return <div>3</div>
                })}
                </>}
            </List>
        </div>
    );
}

export default Menu;