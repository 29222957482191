import { format } from "date-fns";
const time_minute = (d) => format(d, "HH");

const METRIC_MERGE = {
    RA: 'RA',
}

function filter(records, metric = METRIC_MERGE.RA) {
    const listRecord = records.flat().filter(record => record.data[0].group === metric)
    return listRecord;
}

function merge(listRecordRA, group) {
    let dataMerge = [];
    listRecordRA.forEach((r) => {
        const box = group.boxs.find((box) => box.id === r.box_id);
        r.data.map((d) => {
            d.group = box?.name;
            // if (d.value === 0) d.value = Math.floor(Math.random() * 10); // Random
            d.key = time_minute(d.date) + 'h';
            dataMerge.push(d);
            return d;
        });
    });
    listRecordRA.map((r, i) => {
        if (i === 0) {
            r.data = dataMerge;
        } else {
            r.box_id = '';
            r.data = [];
        }
        return r
    });
}

export function BuildChartDataRA(docs, group) {
    const listRecordRA = filter(docs);
    merge(listRecordRA, group);
    return docs;
}