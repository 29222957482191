import React from "react";
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Checkbox } from '@mui/material';
import { TYPE_BOX } from "../../../Shared/Constances/const";
import BoxRain from "../../../Shared/Components/Icon/IconComponent/BoxRain";
import BoxPermeability from "../../../Shared/Components/Icon/IconComponent/BoxPermeability";
import BoxWater from "../../../Shared/Components/Icon/IconComponent/BoxWater";
import BoxDrain from "../../../Shared/Components/Icon/IconComponent/BoxDrain";
import BoxSalinity from "../../../Shared/Components/Icon/IconComponent/BoxSalinity";
import { ReactComponent as VIcon } from "../../../Shared/Components/IconToolBar/v.svg";
const CheckBoxMetric: React.FC<any> = (props) => {

    const handleToggle = (value: string) => () => {
      const currentIndex = props.checkedBoxMetrics.indexOf(value);
      const newChecked = [...props.checkedBoxMetrics];
      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      props.setCheckedBoxMetrics(newChecked);  
    };

  return (
    <>
      <List className="CheckBoxMetric" sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
        { Object.values(TYPE_BOX).map((value) => {
          const labelId = `checkbox-list-label-${value}`;
          const isSelected = props.checkedBoxMetrics.indexOf(value.code) !== -1 ? true : false;
          return (
            <ListItem key={value.code} >
              <ListItemButton className={isSelected ? "checkbox-item active-bgr" : "checkbox-item"} role={undefined} onClick={handleToggle(value.code)} dense>
                <ListItemIcon className="checkbox-btn">   
                  <Checkbox
                    hidden
                    edge="start"
                    checked={isSelected}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} >
                  <div className="item">
                    <div className="icon">
                    {value.code === 'WA' &&  <BoxWater color={isSelected ? 'white' : '#4285F4'} />}
                    {value.code === 'WP' &&  <BoxPermeability color={isSelected ? 'white' : '#4285F4'} />}
                    {value.code === 'RA' &&  <BoxRain color={isSelected ? 'white' : '#4285F4'} />}
                    {value.code === 'DR' &&  <BoxDrain color={isSelected ? 'white' : '#4285F4'} />}
                    {value.code === 'SA' &&  <BoxSalinity color={isSelected ? 'white' : '#4285F4'} />}
                    </div>
                    <div className="note">{value.label}</div>
                    {props.checkedBoxMetrics.indexOf(value.code) !== -1 && <div><VIcon /></div>}
                  </div>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </>
  )
};

export default CheckBoxMetric;
