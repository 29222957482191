import React, { useState } from "react";
import { List, ListItemIcon, ListItemText, ListItemButton, Collapse, Grid } from '@mui/material';
import { ExpandLess, ExpandMore, WhereToVote, ChevronRight, ChromeReaderMode } from '@mui/icons-material';
import { ReactComponent as Rain } from "../../../Shared/Components/IconGeneralInformation/rain.svg";
import { ReactComponent as WaterLevel } from "../../../Shared/Components/IconGeneralInformation/muc_nuoc.svg";
import { ReactComponent as Capacity } from "../../../Shared/Components/IconGeneralInformation/dung_tich.svg";
import { ReactComponent as Flow } from "../../../Shared/Components/IconGeneralInformation/Q_den.svg";
import { ReactComponent as Drain } from "../../../Shared/Components/IconGeneralInformation/ho_dang_xa.svg";
import { ReactComponent as RiverLevel } from "../../../Shared/Components/IconGeneralInformation/muc_nuoc_song.svg";
import ModalNoti from "./ModalNoti";

const ListGroup: React.FC<any> = (props) => {
  const [groupId, setGroupId] = useState();
  const [modal, setModal] = useState("");
  const [open, setOpen] = useState({
    show: true,
    zoneId: ''
  });
  const handleClick = (e, zoneId) => {
    if (open.zoneId === zoneId) {
      setOpen({ ...open, show: !open.show });
    } else {
      setOpen({ ...open, zoneId });
      props.setZoneId(zoneId)
    }
  };

  const onSelectGroup = (e, id, name) => {
    setGroupId(id)
    setModal(name)
  }

 
  const answer = () => {
    setModal("");
    window.localStorage.setItem('groupId', groupId);
    const list = props.listGroup
    const group = list.find(g => g.id === groupId)
    if(group.subdomain){
      window.location.assign(`https://${group.subdomain}`);
    } else {
      window.location.assign(`https://demo.dulieuthuyloivietnam.vn`);
    }
  }

  const close = () => {
    setModal("")
    props.onSelectGroup(groupId)
  }

  return (
    <>
      <ModalNoti open={modal} answer={answer} close={close}/>
      <div className="general-information">
        <div className="title">
          <div className="w-50">
            Thông tin tổng hợp
          </div>
          <div className="w-50">
            <span>Loại dữ liệu</span>
            <select>
              <option>Tất cả</option>
            </select>
          </div>
        </div>
        <div className="content">
          <div className="content-title">
            Cảnh báo theo số liệu quan trắc
          </div>
          <Grid container className="content-item">
            <Grid item xs={2} className="item">
              <div className="icon"><Rain /></div>
              <p className="sensor">Mưa</p>
              <p className="box"><b>20</b> <sub>trạm</sub></p>
            </Grid>
            <Grid item xs={2} className="item">
              <div className="icon"><WaterLevel /></div>
              <p className="sensor">Mực nước</p>
              <p className="box"><b>20</b> <sub>hồ</sub></p>
            </Grid>
            <Grid item xs={2} className="item">
              <div className="icon"><Capacity /></div>
              <p className="sensor">Dung tích</p>
              <p className="box"><b>20</b> <sub>hồ</sub></p>
            </Grid>
            <Grid item xs={2} className="item">
              <div className="icon"><Flow /></div>
              <p className="sensor">Q đến</p>
              <p className="box"><b>20</b> <sub>hồ</sub></p>
            </Grid>
            <Grid item xs={2} className="item">
              <div className="icon"><Drain /></div>
              <p className="sensor">Hồ xả</p>
              <p className="box"><b>20</b> <sub>hồ</sub></p>
            </Grid>
            <Grid item xs={2} className="item">
              <div className="icon"><RiverLevel /></div>
              <p className="sensor">MN sông</p>
              <p className="box"><b>20</b> <sub>trạm</sub></p>
            </Grid>
          </Grid>
        </div>
        <div className="content">
          <div className="content-title">
            Cảnh báo theo số liệu dự báo
          </div>
          <Grid container className="content-item">
            <Grid item xs={1} />
            <Grid item xs={2} className="item">
              <div className="icon"><Rain /></div>
              <p className="sensor">Mưa</p>
              <p className="box"><b>20</b> <sub>trạm</sub></p>
            </Grid>
            <Grid item xs={2} className="item">
              <div className="icon"><WaterLevel /></div>
              <p className="sensor">Mực nước</p>
              <p className="box"><b>20</b> <sub>hồ</sub></p>
            </Grid>
            <Grid item xs={2} className="item">
              <div className="icon"><Capacity /></div>
              <p className="sensor">Dung tích</p>
              <p className="box"><b>20</b> <sub>hồ</sub></p>
            </Grid>
            <Grid item xs={2} className="item">
              <div className="icon"><Flow /></div>
              <p className="sensor">Q đến</p>
              <p className="box"><b>20</b> <sub>hồ</sub></p>
            </Grid>
            <Grid item xs={2} className="item">
              <div className="icon"><RiverLevel /></div>
              <p className="sensor">MN sông</p>
              <p className="box"><b>20</b> <sub>trạm</sub></p>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="list-zone">
        <div className="title">
          Danh sách công trình
        </div>
        {props.listZone.map((zone, index) => {
          let count = 0;
          let listGroup = props.listGroup.map(group => {
            if (group.zone_id === zone.id) {
              count++;
              return (
                <ListItemButton className="group" key={group.id} sx={{ pl: 4 }} onClick={(e) => onSelectGroup(e, group.id, group.name)}>
                  <ListItemIcon>
                    <ChevronRight />
                  </ListItemIcon>
                  <ListItemText >
                    {group.name}
                  </ListItemText>
                </ListItemButton>
              )
            } else {
              return null;
            }
          })

          return <div key={index} >
            <ListItemButton className={(open.zoneId === zone.id && open.show) ? "province-selected province" : "province"} hidden onClick={(e) => handleClick(e, zone.id)}>
              {/* <ListItemIcon>
              <WhereToVote sx={{ color: (open.zoneId === zone.id && open.show ) ? "red" : "" }} />
            </ListItemIcon> */}
              <ListItemText >
                <div className="zone">
                  <b className="name">{zone.name}</b>
                  <div className="count">{count} hồ</div>
                </div>
              </ListItemText>
              {open === zone.id ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={(open.zoneId === zone.id && open.show)} timeout="auto" unmountOnExit>
              <List component="div" disablePadding className="list-group">
                {listGroup}
              </List>
            </Collapse>
          </div>
        })}
      </div>
    </>
  )
};

export default ListGroup;