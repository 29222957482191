import React from "react";

const RainWarning: React.FC<any> = (props) => {
    return (
        <svg width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.4219 1.91388L7.42184 1.91394C6.40063 2.98966 4.76694 4.82944 3.38778 6.92423C2.01161 9.01448 0.875 11.379 0.875 13.5C0.875 15.5223 1.67835 17.4617 3.10831 18.8917C4.53828 20.3217 6.47772 21.125 8.5 21.125C10.5223 21.125 12.4617 20.3217 13.8917 18.8917C15.3217 17.4617 16.125 15.5223 16.125 13.5C16.125 11.383 14.6931 9.78938 13.0728 7.98603L13.053 7.96396L13.0529 7.96392C11.6471 6.40046 10.085 4.65987 9.0792 2.24957C8.90946 1.84072 8.75467 1.41346 8.61972 0.964051L8.56121 0.769199L8.41383 0.909448L8.5 1L8.41382 0.909459L8.4138 0.909479L8.41373 0.909543L8.41349 0.909773L8.41258 0.910636L8.4091 0.913966L8.39554 0.926977L8.34294 0.977856C8.29691 1.02262 8.22937 1.08878 8.14306 1.17469C7.97046 1.34651 7.72277 1.59734 7.4219 1.91388ZM2.4492 13.4678C2.4201 13.4523 2.39549 13.4388 2.37558 13.4278C2.3921 12.393 2.76128 11.1827 3.40457 9.88036L3.4046 9.88031C4.05474 8.56265 4.9371 7.24727 5.84173 6.06351C6.52227 5.17782 7.24059 4.32184 7.99469 3.49794C9.08896 5.79312 10.6094 7.48762 11.8726 8.89547L11.8932 8.91848L11.8932 8.9185L11.8951 8.92049L11.9316 8.95951C12.7981 9.92482 13.4722 10.698 13.9308 11.4151C14.3558 12.0798 14.5909 12.6895 14.6216 13.3509C14.5286 13.3361 14.3952 13.3169 14.2271 13.2977C13.8814 13.2582 13.3883 13.2188 12.7969 13.2188C11.6149 13.2188 10.0354 13.3762 8.45358 14.0089C6.92498 14.6204 5.39226 14.469 4.23533 14.1605C3.65755 14.0064 3.17594 13.8137 2.83908 13.6598C2.67072 13.5828 2.53874 13.5156 2.4492 13.4678ZM4.07943 10.6641L4.02362 10.7758L4.13525 10.8318L5.25275 11.3918L5.36463 11.4478L5.42057 11.3359C6.38909 9.39759 7.42905 8.13272 7.78089 7.78089L7.86928 7.6925L7.78089 7.60411L6.89589 6.71911L6.80738 6.63061L6.71899 6.71923C6.23841 7.20109 5.10966 8.60242 4.07943 10.6641Z" fill={props.color ? props.color : "#6E82EF"}  stroke="white" stroke-width="0.25"/>
        </svg>
    )
};

export default RainWarning;
