import React from "react";

const Warning: React.FC<any> = (props) => {
    return (
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.5 0C11.6438 0 15 3.35625 15 7.5C15 11.6438 11.6438 15 7.5 15C3.35625 15 0 11.6438 0 7.5C0 3.35625 3.35625 0 7.5 0ZM8.55937 8.79375L8.8875 2.7375H6.1125L6.44062 8.79375H8.55937ZM8.475 11.9437C8.7 11.7281 8.82187 11.4281 8.82187 11.0437C8.82187 10.65 8.70938 10.35 8.48438 10.1344C8.25938 9.91875 7.93125 9.80625 7.49062 9.80625C7.05 9.80625 6.72188 9.91875 6.4875 10.1344C6.25313 10.35 6.14062 10.65 6.14062 11.0437C6.14062 11.4281 6.2625 11.7281 6.49688 11.9437C6.74063 12.1594 7.06875 12.2625 7.49062 12.2625C7.9125 12.2625 8.24063 12.1594 8.475 11.9437Z" fill={ props.color ? props.color : "#0B7636" } />
        </svg>
    )
};

export default Warning;
