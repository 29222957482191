import React from "react";
import { MapContainer, useMap } from "react-leaflet";
import LayerMap from "./LayerMap";
import LeafletRuler from "./Draw/LeafletRuler";
import MarkerMap from "./MarkerMap";

// import { type LatLngExpression } from 'leaflet'
// const position: LatLngExpression = [11.080890, 107.163628];

const Map: React.FC<any> = (props) => {
  function MyComponent() {
    const map = useMap();
    map.flyTo([props.center[0], props.center[1]], map.getZoom() < props.zoom ? props.zoom : map.getZoom())
    return null;
  }

  function selectBox(e, boxId): any {
    let result = props.group.boxs.find(b => b.id === boxId);
    props.setBox(result);
    // props.setTypeMenu(3);
    // props.setZoom(14);
    // props.setToggleTable(true)
    // props.setToggleChart(true)
  }

  return (
    <div className="Map">
      <MapContainer id="MapSS" center={props.center} zoom={props.zoom}>
        <LayerMap layer={props.layer} />
        <MyComponent />
        {props.group?.boxs.map((box, index) => {
          if (props.checkedBoxMetrics.length === 1 || (props.checkedBoxMetrics.length > 1 && props.checkedBoxMetrics.indexOf(box.type) !== -1)) {
            return (
              <MarkerMap selectBox={selectBox} index={index} box={box} record={props.dataTable[0]} />
            )
          } else return null
        })}
        <LeafletRuler />
      </MapContainer>
    </div>
  )
};

export default Map;


