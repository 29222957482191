export const METRIC_GROUPS = [
    { label: 'mực nước', metric_codes: ['WA', 'WAU', 'WAD', 'WAO'], unit: 'm' },
    { label: 'độ mở cống', metric_codes: ['DR', 'DR1', 'DR2', 'DR3', 'DR4'], unit: 'm' },
    { label: 'đo thấm', metric_codes: ['WP', 'WP1', 'WP2', 'WP3', 'WP4'], unit: 'mH2O' },
    { label: 'độ mặn', metric_codes: ['SA'], unit: 'S‰' },
    { label: 'lượng mưa', metric_codes: ['RA'], unit: 'mm' },
    { label: 'nhiệt độ', metric_codes: ['TE'], unit: '℃' },
    { label: 'điện áp', metric_codes: ['VO'], unit: 'V' },
];

export const colorChart = [ '#ff6384', '#36a2eb', '#ff9f40', '#4bc0c0', '#8bc34a']

export function dataChart(report) {
    const result = report.map((d, i) => {       
        let objChart = {
            label: d.label,
            fill: 'none',
            data: d.data,
            backgroundColor: colorChart[i],
            pointRadius: 2,
            borderColor: colorChart[i],
            borderWidth: 1,
            tension: 0.4,
        }
        return objChart
    });  
    return result;
}