import { Http } from "../../../Shared/Services/Http";

const API_ENPOINT = {
    lIST_ZONE: "zone/list",
    LIST_GROUP : "zone/box_group/list?zone_id=",
    LIST_RECORD_BY_BOX: "sensor/box",
    LIST_RECORD_BY_GROUP: "sensor/group",
    LIST_METRIC: "sensor/metric/list",
}

async function ListZone() {  
    const res = await Http.get(`${API_ENPOINT.lIST_ZONE}`);
    const doc = await res.data;
    return doc;
}

async function ListGroup(zoneId: string) {  
    const res = await Http.get(`${API_ENPOINT.LIST_GROUP}` + zoneId);
    const doc = await res.data;
    return doc;
}

async function ListMetric() {
    const result = await Http.get(`${API_ENPOINT.LIST_METRIC}`);
    return result.data;
}

async function ListByBox(box_id: string, filter?: any) {
    const ONE_HOUR = 1000 * 60 * 60;
    const now = Date.now();
    const max_ts = now;
    const min_ts = now - 12 * ONE_HOUR; 
    const result = await Http.get(`${API_ENPOINT.LIST_RECORD_BY_BOX}?id=${box_id}&&max_ts=${max_ts}&&min_ts=${min_ts}`);
    return result.data;
}

async function ListByGroup(group_id: string, filter?: any) {
    const ONE_HOUR = 1000 * 60 * 60;
    const now = Date.now();
    const max_ts = now;
    const min_ts = now - 12 * ONE_HOUR; 
    const result = await Http.get(`${API_ENPOINT.LIST_RECORD_BY_GROUP}?id=${group_id}&&max_ts=${max_ts}&&min_ts=${min_ts}`);
    return result.data;
}

export const AppApi = {
    ListGroup, ListByBox, ListMetric, ListZone, ListByGroup
}
