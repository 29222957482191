export const DOI_ONG_TUONG = [
    [
        {
            "lat": 20.816592433856933,
            "lng": 105.33280331388778
        },
        {
            "lat": 20.814826116621933,
            "lng": 105.33085018299361
        },
        {
            "lat": 20.813942950241554,
            "lng": 105.3301204417804
        },
        {
            "lat": 20.81271855192654,
            "lng": 105.32973410819694
        },
        {
            "lat": 20.811233202914508,
            "lng": 105.3288755891226
        },
        {
            "lat": 20.810631030226585,
            "lng": 105.32810292195568
        },
        {
            "lat": 20.810309870476118,
            "lng": 105.32711562502017
        },
        {
            "lat": 20.810610957762222,
            "lng": 105.32606393915411
        },
        {
            "lat": 20.81105255136071,
            "lng": 105.32514103114917
        },
        {
            "lat": 20.811032478952477,
            "lng": 105.32456153077398
        },
        {
            "lat": 20.8100890727508,
            "lng": 105.32344545597729
        },
        {
            "lat": 20.808985079273338,
            "lng": 105.32247962201865
        },
        {
            "lat": 20.808202242636025,
            "lng": 105.32226499225004
        },
        {
            "lat": 20.807359183250018,
            "lng": 105.32342399300046
        },
        {
            "lat": 20.806756995088158,
            "lng": 105.32462591970456
        },
        {
            "lat": 20.805974146882164,
            "lng": 105.32640734678384
        },
        {
            "lat": 20.805632904084497,
            "lng": 105.32790975516394
        },
        {
            "lat": 20.805351880024208,
            "lng": 105.32999166391926
        },
        {
            "lat": 20.805392026350603,
            "lng": 105.33185894290597
        },
        {
            "lat": 20.80533180685702,
            "lng": 105.33370475891584
        },
        {
            "lat": 20.805392026350603,
            "lng": 105.33471351882822
        },
        {
            "lat": 20.80617487757888,
            "lng": 105.33507838943483
        },
        {
            "lat": 20.80737925614733,
            "lng": 105.33533594515713
        },
        {
            "lat": 20.808663916016435,
            "lng": 105.335808130648
        },
        {
            "lat": 20.809747839266457,
            "lng": 105.33578666767116
        },
        {
            "lat": 20.811032478952477,
            "lng": 105.33576520469428
        },
        {
            "lat": 20.812056168364485,
            "lng": 105.33546472301826
        },
        {
            "lat": 20.81350136511071,
            "lng": 105.33490668561993
        },
        {
            "lat": 20.814705685147526,
            "lng": 105.3346062039439
        },
        {
            "lat": 20.815950139075778,
            "lng": 105.33452035203648
        },
        {
            "lat": 20.816572362186452,
            "lng": 105.33419840738361
        },
        {
            "lat": 20.816873436962876,
            "lng": 105.333833536777
        },
        {
            "lat": 20.816933651845968,
            "lng": 105.33331842533238
        },
        {
            "lat": 20.816574908644796,
            "lng": 105.33286307694416
        },
        {
            "lat": 20.81655483697199,
            "lng": 105.33283088247887
        }
    ]
]

export const HO_DA_TON = [
    {
        "lat": 11.344550870033503,
        "lng": 107.47512377332897
    },
    {
        "lat": 11.344130101080077,
        "lng": 107.47456587385388
    },
    {
        "lat": 11.343793485471004,
        "lng": 107.4739221436903
    },
    {
        "lat": 11.342909867609576,
        "lng": 107.47370756696911
    },
    {
        "lat": 11.343372715401738,
        "lng": 107.47272051405163
    },
    {
        "lat": 11.343078175984418,
        "lng": 107.47173346113415
    },
    {
        "lat": 11.342741559135552,
        "lng": 107.47027433943005
    },
    {
        "lat": 11.342194555910062,
        "lng": 107.46993101667614
    },
    {
        "lat": 11.34156339704054,
        "lng": 107.46980227064341
    },
    {
        "lat": 11.341058468940847,
        "lng": 107.47121847700329
    },
    {
        "lat": 11.340806004556324,
        "lng": 107.46975935529919
    },
    {
        "lat": 11.34025899762447,
        "lng": 107.4693302018568
    },
    {
        "lat": 11.339417446453435,
        "lng": 107.46920145582409
    },
    {
        "lat": 11.339164980618781,
        "lng": 107.4693302018568
    },
    {
        "lat": 11.338323426225438,
        "lng": 107.47027433943005
    },
    {
        "lat": 11.337608103042353,
        "lng": 107.47014559339733
    },
    {
        "lat": 11.336976934040855,
        "lng": 107.46975935529919
    },
    {
        "lat": 11.33630368556942,
        "lng": 107.4698881013319
    },
    {
        "lat": 11.335083418672626,
        "lng": 107.46997393202038
    },
    {
        "lat": 11.333821068124747,
        "lng": 107.46963060926647
    },
    {
        "lat": 11.33209584669416,
        "lng": 107.46928728651257
    },
    {
        "lat": 11.330160220013958,
        "lng": 107.46937311720104
    },
    {
        "lat": 11.328477055728575,
        "lng": 107.46911562513561
    },
    {
        "lat": 11.326246847792548,
        "lng": 107.46847189497204
    },
    {
        "lat": 11.324774059434025,
        "lng": 107.46812857221813
    },
    {
        "lat": 11.323553743336149,
        "lng": 107.46769941877575
    },
    {
        "lat": 11.322333422033624,
        "lng": 107.46744192671032
    },
    {
        "lat": 11.323259183498255,
        "lng": 107.46636904310436
    },
    {
        "lat": 11.323343343482879,
        "lng": 107.4655107362196
    },
    {
        "lat": 11.323259183498255,
        "lng": 107.46430910658093
    },
    {
        "lat": 11.321996780758479,
        "lng": 107.46426619123669
    },
    {
        "lat": 11.322375502165182,
        "lng": 107.46357954572888
    },
    {
        "lat": 11.323511663377868,
        "lng": 107.46323622297497
    },
    {
        "lat": 11.324016622468733,
        "lng": 107.46194876264782
    },
    {
        "lat": 11.323848302870795,
        "lng": 107.46023214887829
    },
    {
        "lat": 11.323637903234134,
        "lng": 107.45898760389538
    },
    {
        "lat": 11.324395341202026,
        "lng": 107.458558450453
    },
    {
        "lat": 11.325657733359014,
        "lng": 107.45782888960095
    },
    {
        "lat": 11.325615653710202,
        "lng": 107.4568847520277
    },
    {
        "lat": 11.322501742522702,
        "lng": 107.45611227583142
    },
    {
        "lat": 11.320818533176812,
        "lng": 107.45658434461804
    },
    {
        "lat": 11.320986854556903,
        "lng": 107.45808638166638
    },
    {
        "lat": 11.321071015209826,
        "lng": 107.45954550337048
    },
    {
        "lat": 11.319219475128751,
        "lng": 107.4589017732069
    },
    {
        "lat": 11.31778873140832,
        "lng": 107.46006048750134
    },
    {
        "lat": 11.316021332234406,
        "lng": 107.46126211714001
    },
    {
        "lat": 11.313243968633648,
        "lng": 107.46375120710583
    },
    {
        "lat": 11.313057095918452,
        "lng": 107.4674804415554
    },
    {
        "lat": 11.314572025846811,
        "lng": 107.46842457912864
    },
    {
        "lat": 11.316676081895958,
        "lng": 107.46876790188254
    },
    {
        "lat": 11.317433538288535,
        "lng": 107.47460438869895
    },
    {
        "lat": 11.318022669652771,
        "lng": 107.47863843105735
    },
    {
        "lat": 11.319200928743964,
        "lng": 107.48335911892356
    },
    {
        "lat": 11.321136629578659,
        "lng": 107.48473240993918
    },
    {
        "lat": 11.324166395904664,
        "lng": 107.48473240993918
    },
    {
        "lat": 11.328121875876231,
        "lng": 107.48413159511985
    },
    {
        "lat": 11.329973358344754,
        "lng": 107.4831874575466
    },
    {
        "lat": 11.332834716761933,
        "lng": 107.48275830410422
    },
    {
        "lat": 11.333928758000509,
        "lng": 107.48404576443137
    },
    {
        "lat": 11.33561189019131,
        "lng": 107.48438908718528
    },
    {
        "lat": 11.33729501247033,
        "lng": 107.485419055447
    },
    {
        "lat": 11.339146435527462,
        "lng": 107.48498990200461
    },
    {
        "lat": 11.339651367006484,
        "lng": 107.48327328823508
    },
    {
        "lat": 11.33805241426179,
        "lng": 107.48087002895774
    },
    {
        "lat": 11.337715791491215,
        "lng": 107.47898175381125
    },
    {
        "lat": 11.339398901378528,
        "lng": 107.47786595486106
    },
    {
        "lat": 11.34057707240034,
        "lng": 107.47675015591086
    },
    {
        "lat": 11.342428474189116,
        "lng": 107.47657849453391
    },
    {
        "lat": 11.3438590946251,
        "lng": 107.47649266384543
    },
    {
        "lat": 11.344519760207834,
        "lng": 107.47606639692096
    },
    {
        "lat": 11.344751182893168,
        "lng": 107.47576062509326
    },
    {
        "lat": 11.344761702101673,
        "lng": 107.4753904802492
    },
    {
        "lat": 11.344546058249701,
        "lng": 107.47512225934773
    }
]

export const SONG_MAY = [
    {
        "lat": 10.987121629759082,
        "lng": 106.98138179490344
    },
    {
        "lat": 10.98821697413391,
        "lng": 106.97872104356067
    },
    {
        "lat": 10.988301231225106,
        "lng": 106.97691859910266
    },
    {
        "lat": 10.986616084830235,
        "lng": 106.9764036149718
    },
    {
        "lat": 10.984172605466966,
        "lng": 106.97648944566028
    },
    {
        "lat": 10.982150400353154,
        "lng": 106.9778627366759
    },
    {
        "lat": 10.980633737428384,
        "lng": 106.97957935044543
    },
    {
        "lat": 10.978695767910816,
        "lng": 106.97992267319934
    },
    {
        "lat": 10.976673525291293,
        "lng": 106.97863521287219
    },
    {
        "lat": 10.976252223002994,
        "lng": 106.97700442979112
    },
    {
        "lat": 10.976083701919435,
        "lng": 106.97537364671008
    },
    {
        "lat": 10.97549387736996,
        "lng": 106.9738286943175
    },
    {
        "lat": 10.974819790728642,
        "lng": 106.97245540330186
    },
    {
        "lat": 10.974904051642927,
        "lng": 106.97125377366321
    },
    {
        "lat": 10.976083701919435,
        "lng": 106.9690221757628
    },
    {
        "lat": 10.977094826978734,
        "lng": 106.9672197313048
    },
    {
        "lat": 10.978695767910816,
        "lng": 106.96473064133899
    },
    {
        "lat": 10.977768908428802,
        "lng": 106.96352901170032
    },
    {
        "lat": 10.974736992939775,
        "lng": 106.96389267628548
    },
    {
        "lat": 10.973725859810848,
        "lng": 106.9649226445472
    },
    {
        "lat": 10.971282273798415,
        "lng": 106.96706841175912
    },
    {
        "lat": 10.970355391060123,
        "lng": 106.9689566869056
    },
    {
        "lat": 10.969681292697558,
        "lng": 106.97213242237923
    },
    {
        "lat": 10.969849817432316,
        "lng": 106.9744498509681
    },
    {
        "lat": 10.970439653247343,
        "lng": 106.9759089726722
    },
    {
        "lat": 10.971113749880374,
        "lng": 106.97719643299935
    },
    {
        "lat": 10.970018342070997,
        "lng": 106.97745392506478
    },
    {
        "lat": 10.968248828573016,
        "lng": 106.97831223194954
    },
    {
        "lat": 10.966732094287641,
        "lng": 106.97917053883431
    },
    {
        "lat": 10.966732094287641,
        "lng": 106.98045799916146
    },
    {
        "lat": 10.967743251342442,
        "lng": 106.98114464466927
    },
    {
        "lat": 10.969512767866707,
        "lng": 106.98131630604622
    },
    {
        "lat": 10.970945225866252,
        "lng": 106.98165962880013
    },
    {
        "lat": 10.97263046168326,
        "lng": 106.9825179356849
    },
    {
        "lat": 10.973894382239285,
        "lng": 106.98320458119271
    },
    {
        "lat": 10.974399948947935,
        "lng": 106.98414871876595
    },
    {
        "lat": 10.974568470991906,
        "lng": 106.98612282460091
    },
    {
        "lat": 10.974821253877659,
        "lng": 106.98741028492806
    },
    {
        "lat": 10.97414716570175,
        "lng": 106.98852608387826
    },
    {
        "lat": 10.972883246226958,
        "lng": 106.98955605213997
    },
    {
        "lat": 10.971787844975962,
        "lng": 106.9909293431556
    },
    {
        "lat": 10.970608177549737,
        "lng": 106.99178765004037
    },
    {
        "lat": 10.96934424293978,
        "lng": 106.99324677174447
    },
    {
        "lat": 10.967658988386622,
        "lng": 106.99367592518685
    },
    {
        "lat": 10.96588946076713,
        "lng": 106.99590752308724
    },
    {
        "lat": 10.965215352221854,
        "lng": 106.99968407338021
    },
    {
        "lat": 10.96588946076713,
        "lng": 107.0022589940345
    },
    {
        "lat": 10.966310777827541,
        "lng": 107.00732300465462
    },
    {
        "lat": 10.969007192797715,
        "lng": 107.00861046498177
    },
    {
        "lat": 10.971956368509629,
        "lng": 107.0062930363929
    },
    {
        "lat": 10.973978643417468,
        "lng": 107.00388977711556
    },
    {
        "lat": 10.976927769514445,
        "lng": 107.00131485646126
    },
    {
        "lat": 10.979539828041508,
        "lng": 106.99916908924935
    },
    {
        "lat": 10.981309273918034,
        "lng": 106.99762413685677
    },
    {
        "lat": 10.982404639861326,
        "lng": 106.99848244374154
    },
    {
        "lat": 10.984258326815043,
        "lng": 106.99951241200326
    },
    {
        "lat": 10.985774971119216,
        "lng": 106.99916908924935
    },
    {
        "lat": 10.985774971119216,
        "lng": 106.99770996754525
    },
    {
        "lat": 10.986870320492212,
        "lng": 106.99925491993783
    },
    {
        "lat": 10.987881408612841,
        "lng": 107.00148651783822
    },
    {
        "lat": 10.989903574460847,
        "lng": 107.00037071888802
    },
    {
        "lat": 10.990577626663729,
        "lng": 106.99839661305306
    },
    {
        "lat": 10.990661883080813,
        "lng": 106.99659416859505
    },
    {
        "lat": 10.99293679724534,
        "lng": 106.99221680348275
    },
    {
        "lat": 10.994874673250134,
        "lng": 106.98955605213997
    },
    {
        "lat": 10.995380204026748,
        "lng": 106.98749611561654
    },
    {
        "lat": 10.993021052988578,
        "lng": 106.98620865528939
    },
    {
        "lat": 10.990240600754781,
        "lng": 106.98629448597787
    },
    {
        "lat": 10.988723979400358,
        "lng": 106.98552200978158
    },
    {
        "lat": 10.988386951374052,
        "lng": 106.98337624256966
    },
    {
        "lat": 10.986786062992481,
        "lng": 106.98183129017708
    },
    {
        "lat": 10.986870320492212,
        "lng": 106.98131630604622
    }
]

export const SONG_LUY = [
    {
        "lat": 11.388287826684238,
        "lng": 108.3375041477848
    },
    {
        "lat": 11.388708530453034,
        "lng": 108.33544421126136
    },
    {
        "lat": 11.3875305583324,
        "lng": 108.33450007368812
    },
    {
        "lat": 11.388119545002493,
        "lng": 108.3331267826725
    },
    {
        "lat": 11.389381655188881,
        "lng": 108.33286929060706
    },
    {
        "lat": 11.391064460058876,
        "lng": 108.33278345991859
    },
    {
        "lat": 11.391569299578174,
        "lng": 108.33183932234535
    },
    {
        "lat": 11.39123273999821,
        "lng": 108.33029436995277
    },
    {
        "lat": 11.390054778331704,
        "lng": 108.32935023237951
    },
    {
        "lat": 11.38761469935994,
        "lng": 108.329436063068
    },
    {
        "lat": 11.386268439933108,
        "lng": 108.33029436995277
    },
    {
        "lat": 11.384501464767958,
        "lng": 108.33209681441078
    },
    {
        "lat": 11.383407617500305,
        "lng": 108.33252596785316
    },
    {
        "lat": 11.38374418633819,
        "lng": 108.33106684614906
    },
    {
        "lat": 11.382902763497155,
        "lng": 108.33038020064124
    },
    {
        "lat": 11.381724767340344,
        "lng": 108.3308951847721
    },
    {
        "lat": 11.379705334014533,
        "lng": 108.33175349165687
    },
    {
        "lat": 11.3781907496189,
        "lng": 108.33261179854163
    },
    {
        "lat": 11.377012733963014,
        "lng": 108.33278345991859
    },
    {
        "lat": 11.374067673502408,
        "lng": 108.33175349165687
    },
    {
        "lat": 11.371459165949076,
        "lng": 108.33450007368812
    },
    {
        "lat": 11.371375020146445,
        "lng": 108.3363883488346
    },
    {
        "lat": 11.370785998832122,
        "lng": 108.33904910017738
    },
    {
        "lat": 11.371627457479791,
        "lng": 108.3415381901432
    },
    {
        "lat": 11.372300622608428,
        "lng": 108.34437060286292
    },
    {
        "lat": 11.373731093224908,
        "lng": 108.3488337986637
    },
    {
        "lat": 11.374740832864179,
        "lng": 108.35089373518714
    },
    {
        "lat": 11.376760301402662,
        "lng": 108.35321116377601
    },
    {
        "lat": 11.379116329929262,
        "lng": 108.35389780928382
    },
    {
        "lat": 11.380546766308049,
        "lng": 108.35372614790687
    },
    {
        "lat": 11.381724767340344,
        "lng": 108.35278201033363
    },
    {
        "lat": 11.381724767340344,
        "lng": 108.3509795658756
    },
    {
        "lat": 11.380546766308049,
        "lng": 108.34866213728675
    },
    {
        "lat": 11.379284616935115,
        "lng": 108.34668803145179
    },
    {
        "lat": 11.378779755619107,
        "lng": 108.34565806319007
    },
    {
        "lat": 11.379116329929262,
        "lng": 108.3441131107975
    },
    {
        "lat": 11.380294336881143,
        "lng": 108.34394144942054
    },
    {
        "lat": 11.381808910084658,
        "lng": 108.34497141768226
    },
    {
        "lat": 11.38315519061068,
        "lng": 108.34540057112463
    },
    {
        "lat": 11.383407617500305,
        "lng": 108.34325480391273
    },
    {
        "lat": 11.384249038848617,
        "lng": 108.34394144942054
    },
    {
        "lat": 11.38601601558136,
        "lng": 108.3441131107975
    },
    {
        "lat": 11.386520864060905,
        "lng": 108.34265398909339
    },
    {
        "lat": 11.386436722709865,
        "lng": 108.34170985152015
    },
    {
        "lat": 11.387278135100502,
        "lng": 108.34016489912756
    },
    {
        "lat": 11.387698840362582,
        "lng": 108.338619946735
    },
    {
        "lat": 11.388203685855801,
        "lng": 108.33724665571937
    }

]