import { Icon } from "leaflet";

export const iconPlace = new Icon({
    iconUrl: 'icon-svg/place.svg',
    iconSize: [30, 40]
});

export const iconWA = new Icon({
    iconUrl: 'icon-svg/MucNuoc.svg',
    iconSize: [30, 40]
});

export const iconWP = new Icon({
    iconUrl: 'icon-svg/DoTham.svg',
    iconSize: [20, 30]
});

export const iconRA = new Icon({
    iconUrl: 'icon-svg/Vector.svg',
    iconSize: [20, 30]
});

export const iconSA = new Icon({
    iconUrl: 'icon-svg/DoMan.svg',
    iconSize: [20, 30]
});

export const iconDR = new Icon({
    iconUrl: 'icon-svg/DoMoCong.svg',
    iconSize: [20, 30]
});