import React, { useEffect, useRef } from "react";
import { RECTS_DEFAULT } from "./water_proof_data";
import { AppApi } from "../Components/App/AppApi";


const WaterProofView = (props) => {
  const canvasRef  = useRef(null);

  // function draw(record) {
  //   if (!props.box_id) return;
  //       const rect_defaut = RECTS_DEFAULT.find((r) => r.box_id === props.box_id);
  //       const rect = JSON.parse(JSON.stringify(rect_defaut));

  //       Object.keys(rect.value).map(k => {
  //         const adjust = +(record[k] - rect.value[k].default) * rect.ratio
  //         // kết quả = (bản ghi - đi giá trị thấp nhất của trạm) * tỉ lệ
  //         rect.value[k].value = Math.round(record[k] * 100) / 100 ;
  //         rect.value[k].y_default = rect.value[k].y;
  //         rect.value[k].h += adjust;
  //         rect.value[k].y -= adjust;
  //         return k;
  //       })
  //       const canvas = canvasRef.current;
  //       if(!canvas) return;
  //       const ctx = canvas.getContext("2d");
  //       const base_dir = "../images/water_proof";
  //       const image = new Image();
  //       image.onload = () => {
  //         canvas.width = image.width;
  //         canvas.height = image.height;
  //         ctx.drawImage(image, 0, 0);
  //         Object.values(rect.value).map((v) => {

  //           ctx.fillStyle = "#FF0000";
  //           ctx.fillRect(v.x, v.y, v.w, v.h);

  //           ctx.font = "bold 20px Times New Roman";
  //           ctx.fillStyle = "#000000";
  //           ctx.fillText(v.name, v.x - 10, v.y_default + 30);


  //           ctx.fillStyle='#a0ffa0';
  //           ctx.fillRect(v.x - 35, v.y_default + 40, 80 , 30 )

  //           ctx.font = "bold 20px Times New Roman";
  //           ctx.fillStyle = "#19860d";
  //           ctx.fillText(v.value, v.x - 25, v.y_default + 60);

  //           return v;
  //         });
  //       };
  //       image.src = `${base_dir}/${rect.image}`;
  // }

  useEffect(() => {
    if(props.box_id) {
      AppApi.ListByBox(props.box_id, { limit: 1, min_ts: 1 })
      .then((res) => {
        let record = res.records[0];
        //reverseAdjustRecord(box_id, record)
               const rect_defaut = RECTS_DEFAULT.find((r) => r.box_id === props.box_id);
        const rect = JSON.parse(JSON.stringify(rect_defaut));

        Object.keys(rect.value).map(k => {
          const adjust = +(record[k] - rect.value[k].default) * rect.ratio
          // kết quả = (bản ghi - đi giá trị thấp nhất của trạm) * tỉ lệ
          rect.value[k].value = Math.round(record[k] * 100) / 100 ;
          rect.value[k].y_default = rect.value[k].y;
          rect.value[k].h += adjust;
          rect.value[k].y -= adjust;
          return k;
        })
        const canvas = canvasRef.current;
        if(!canvas) return;
        const ctx = canvas.getContext("2d");
        const base_dir = "../images/water_proof";
        const image = new Image();
        image.onload = () => {
          canvas.width = image.width;
          canvas.height = image.height;
          ctx.drawImage(image, 0, 0);
          Object.values(rect.value).map((v) => {

            ctx.fillStyle = "#FF0000";
            ctx.fillRect(v.x, v.y, v.w, v.h);

            ctx.font = "bold 20px Roboto, Helvetica, Arial, sans-serif";
            ctx.fillStyle = "#000000";
            ctx.fillText(v.name, v.x - 10, v.y_default + 30);


            ctx.fillStyle='#a0ffa0';
            ctx.fillRect(v.x - 35, v.y_default + 40, 80 , 30 )

            ctx.font = "bold 20px Roboto, Helvetica, Arial, sans-serif";
            ctx.fillStyle = "#19860d";
            ctx.fillText(v.value, v.x - 25, v.y_default + 60);

            return v;
          });
        };
        image.src = `${base_dir}/${rect.image}`;
      })
    }
  }, [props.box_id])

  return (
    <div className="item-chart WaterProofView">
        <canvas style={{ width: '47vw' }} ref={canvasRef} />
    </div>
  )
};

export default WaterProofView;
