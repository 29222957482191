import React from "react";

const DataIcon: React.FC<any> = (props) => {
    const color = props.color ? props.color : "#4285F4"
    const fillColor = props.fillColor ? props.fillColor : "#4285F4"
    return (
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="30.5" cy="33.5" r="26.75" fill={fillColor} stroke={color} stroke-width="1.5"/>
            <path d="M46.6667 36.6667C49.244 36.6667 51.3333 34.5773 51.3333 32C51.3333 29.4227 49.244 27.3333 46.6667 27.3333C44.0893 27.3333 42 29.4227 42 32C42 34.5773 44.0893 36.6667 46.6667 36.6667Z" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.667 32H18.0003L23.3337 18.6667L31.3337 45.3333L36.667 32H42.0003" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
};

export default DataIcon;
