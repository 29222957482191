export const WaterProofAdjust = {
  // Song May do tham 1
  '3NP7U757': {
    WP1: 27.5,
    WP2: 28.2,
    WP3: 30.2,
  },

  // Song May do tham 2
  'JVPRAJG1': {
    WP1: 27.5,
    WP2: 29.5,
    WP3: 29.5,
  },

  // Song May do tham 3
  '9XHUR90X': {
    WP3: 27.5,
    WP2: 28.5,
    WP1: 29.5,
  }
}

// const WaterProofSwap = {
//   // song may
//   '3NP7U757': { 'WP1': 'WP3', 'WP3': 'WP1' },
//   'JVPRAJG1': { 'WP1': 'WP3', 'WP3': 'WP1' },
//   '9XHUR90X': { 'WP1': 'WP3', 'WP3': 'WP1' },

//   // da ton
//   'J6ABPQ3Q': { 'WP1': 'WP4', 'WP2': 'WP3', 'WP3': 'WP2', 'WP4': 'WP1' },
//   '9IEWA0VX': { 'WP1': 'WP4', 'WP2': 'WP3', 'WP3': 'WP2', 'WP4': 'WP1' },
//   'BBZ0L1JP': { 'WP1': 'WP4', 'WP2': 'WP3', 'WP3': 'WP2', 'WP4': 'WP1' },
// }

export const RECTS_DEFAULT = [
  {
    box_id: "J6ABPQ3Q",
    image: "daton1.png",
    value: {
      WP1: { x: 340, y: 460, w: 13, h: 2, default: 124.12, name: 'PZ1' },
      WP2: { x: 489, y: 470, w: 13, h: 2, default: 123.5, name: 'PZ2' },
      WP3: { x: 664, y: 485, w: 13, h: 2, default: 122.56, name: 'PZ3' },
      WP4: { x: 799, y: 460, w: 13, h: 2, default: 124.12, name: 'PZ4' },
    },
    ratio: 16
  },
  {
    box_id: "9IEWA0VX",
    image: "daton2.png",
    value: {
      WP1: { x: 340, y: 455, w: 13, h: 2, default: 124.43, name: 'PZ1' },
      WP2: { x: 505, y: 461, w: 13, h: 2, default: 124.06, name: 'PZ2' },
      WP3: { x: 739, y: 491, w: 13, h: 2, default: 122.18, name: 'PZ3' },
      WP4: { x: 868, y: 469, w: 13, h: 2, default: 123.57, name: 'PZ4' },
    },
    ratio: 16
  },
  {
    box_id: "BBZ0L1JP",
    image: "daton3.png",
    value: {
      WP1: { x: 340, y: 450, w: 13, h: 2, default: 124.75, name: 'PZ1' },
      WP2: { x: 526, y: 468, w: 13, h: 2, default: 123.62, name: 'PZ2' },
      WP3: { x: 726, y: 491, w: 13, h: 2, default: 122.18, name: 'PZ3' },
      WP4: { x: 854, y: 469, w: 13, h: 2, default: 123.57, name: 'PZ4' },
    },
    ratio: 16
  },
  {
    box_id: "3NP7U757",
    image: "songmay1.png",
    value: {
      WP1: { x: 369, y: 450, w: 13, h: 2, default: 30.75, name: 'PZ1' },
      WP2: { x: 489, y: 479, w: 13, h: 2, default: 28.94, name: 'PZ2' },
      WP3: { x: 729, y: 492, w: 13, h: 2, default: 28.12, name: 'PZ3' }
    },
    ratio: 16
  },
  {
    box_id: "JVPRAJG1",
    image: "songmay2.png",
    value: {
      WP1: { x: 369, y: 455, w: 13, h: 2, default: 30.43, name: 'PZ1' },
      WP2: { x: 489, y: 461, w: 13, h: 2, default: 30.06, name: 'PZ2' },
      WP3: { x: 739, y: 492, w: 13, h: 2, default: 28.12, name: 'PZ3' }
    },
    ratio: 16
  },
  {
    box_id: "9XHUR90X",
    image: "songmay3.png",
    value: {
      WP1: { x: 369, y: 455, w: 13, h: 2, default: 30.43, name: 'PZ1' },
      WP2: { x: 489, y: 477, w: 13, h: 2, default: 28.93, name: 'PZ2' },
      WP3: { x: 725, y: 494, w: 13, h: 2, default: 28, name: 'PZ3' }
    },
    ratio: 16
  }
];