import React, { useEffect, useState } from "react";
import { ReactComponent as ExportIcon } from "../../Shared/Components/Icon/export.svg";
import CloseIcon from '@mui/icons-material/Close';
import { Alert, Skeleton  } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const DataTable: React.FC<any> = (props) => {
   const [currentMetrics, setCurrentMetrics] = useState([])
   useEffect(() => {
      if (props.dataTable && props.dataTable[0]) {
         let arr = []
         Object.keys(props.dataTable[0]).forEach(key => {
            props.metrics.forEach(metric => {
               if (metric.code === key) arr.push(metric)
            });
         })
         setCurrentMetrics([...arr])
      }
   }, [props.dataTable, props.metrics])

   const onChangeBox = (e) => {
      const boxId = e.target.value;
      const result = props.group.boxs.find(b => b.id === boxId);
      props.setBox(result);
   }

   const next = () => {
      if(props.group?.boxs.length < 0) return;
      const boxs = props.group.boxs || [];
      let pos;
      boxs.forEach((b, i) => {
         if(b.id === props.box?.id) pos = i;
      })
      if(pos === boxs.length - 1) pos = 0;
      else pos += 1;
      props.setBox({...boxs[pos]})

   }

   const previous = () => {
      if(props.group?.boxs.length < 0) return;
      const boxs = props.group.boxs || [];
      let pos;
      boxs.forEach((b, i) => {
         if(b.id === props.box?.id) pos = i;
      })
      if(pos === 0) pos = boxs.length - 1;
      else pos -= 1;
      props.setBox({...boxs[pos]})
   }

   return (
      <div className="DataTable">
         <div className="title">
            <div className="btn-export"><u>Xuất báo cáo </u><ExportIcon /></div>
            <div className="cursor content">Quan trắc tổng hợp: 
               {/* <span style={{ color: '#2F3193' }}>{props.box.name}</span> */}
                  <select className="content--select-box" value={props.box?.id} onChange={onChangeBox}>
                     {props.group?.boxs?.map(b => {
                        return <option key={b.id} value={b.id}>{b.name}</option>
                     })}
                  </select>
               </div>
            <div className="btn-close" onClick={e => props.setToggleTable(!props.toggleTable)}><CloseIcon /></div>
         </div>
         <div className="next" onClick={next}><ArrowForwardIosIcon /></div>
            <div className="previous" onClick={previous}><ArrowBackIosIcon /></div>
         <div className="item">
            {props.loadingBox ?
               <>
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton animation={false} />
               </>
               : props.dataTable.length > 0 ?
               <table>
                  <thead>
                     <tr>
                        <th className="date-time">Thời điểm</th>
                        {currentMetrics.map((c, i) => {
                           return <th key={i}>{c.name}</th>
                        })}
                     </tr>
                  </thead>
                  <tbody>
                     {props.dataTable.map((data, index) => {
                        return <tr key={index}>
                           <td className="date-time">{(new Date(data.t * 1000)).toLocaleString('en-GB', { timeZone: 'UTC' })}</td>
                           {currentMetrics.map((metric, i) => {
                              return <td key={i}>{data[metric.code]}</td>
                           })}
                        </tr>
                     })}
                  </tbody>
               </table>
               :
               <Alert severity="error">Không có dữ liệu!</Alert>
            }
         </div>
      </div>
   )
};

export default DataTable;
