import React, { useState } from "react";
import { ReactComponent as World } from "../../../Shared/Components/IconHorizonToolBar/world.svg";
import { ReactComponent as Plus } from "../../../Shared/Components/IconHorizonToolBar/plus.svg";
import { ReactComponent as Minus } from "../../../Shared/Components/IconHorizonToolBar/minus.svg";
import { ReactComponent as Polygon} from "../../../Shared/Components/IconHorizonToolBar/polygon.svg";
import { ReactComponent as Vector } from "../../../Shared/Components/IconHorizonToolBar/vector.svg";

const HorizonToolBar: React.FC<any> = (props) => {
    const [toggleMenu, setToggleMenu] = useState(0);
    const toggle = (e, type) => {
        if(toggleMenu !== type) {
            setToggleMenu(type)
        } else {
            setToggleMenu(0)
        }
    }

    return (
        <div className="HorizonToolBar" >
            <div className="content">
                <div className={toggleMenu === 1 ? "icon-item bgr-active" : "icon-item"} onClick={(e) => toggle(e, 1)} >
                    <World />
                </div>
                <div className={toggleMenu === 2 ? "icon-item bgr-active" : "icon-item"} onClick={(e) => toggle(e, 2)}>
                    <Plus/>
                </div>
                <div className={toggleMenu === 3 ? "icon-item bgr-active" : "icon-item"} onClick={(e) => toggle(e, 3)}>
                    <Minus/>
                </div>
                {/* <div className={toggleMenu === 4 ? "icon-item bgr-active" : "icon-item"} onClick={(e) => toggle(e, 4)}>
                    <Polygon />
                </div> */}
                <div className={toggleMenu === 4 ? "icon-item bgr-active" : "icon-item"} onClick={(e) => toggle(e, 4)}>
                    <Vector />
                </div>
            </div>
        </div>
    )
};

export default HorizonToolBar;
