import { format } from "date-fns";
const time_minute = (d) => format(d, "HH:mm");
const date_time = (d) => format(d, "HH:mm dd/MM/yyyy");

export const METRIC_GROUPS = [
    { label: 'mực nước', metric_codes: ['WA', 'WAU', 'WAD', 'WAO', 'V'], unit: 'm', chart_type: 'dualVolume' },
    { label: 'độ mở cống', metric_codes: ['DR', 'DR1', 'DR2', 'DR3', 'DR4', 'Q'], unit: 'm', chart_type: 'dualQ' },
    { label: 'đo thấm', metric_codes: ['WP', 'WP1', 'WP2', 'WP3', 'WP4'], unit: 'mH2O' },
    { label: 'độ mặn', metric_codes: ['SA'], unit: 'S‰' },
    { label: 'lượng mưa', metric_codes: ['RA'], unit: 'mm', chart_type: 'dualRA', type: 'groupbar' },
    { label: 'nhiệt độ', metric_codes: ['TE'], unit: '℃' },
    { label: 'điện áp', metric_codes: ['VO'], unit: 'V' },
];


const Axes = {
    /** @return {import("@carbon/charts").interfaces.AxisOptions} */
    Time: () => ({
        mapsTo: "date",
        scaleType: "time",
        ticks: {
            formatter: time_minute,
        },
        title: 'Thời gian',
    }),
};

/** @return {import("@carbon/charts").interfaces.TimeScaleOptions} */
const TimeScale = () => ({
    timeIntervalFormats: {
        
    },
    localeObject: {
        code: 'vi',
    }
});

/** @return {import("@carbon/charts").interfaces.TooltipOptions} */
const Tooltip = () => ({
    showTotal: false,
    groupLabel: 'Chỉ số',
    valueFormatter: (value, label) => {
        if (value.getTime) {
            return date_time(value);
        }
        return value;
    }
});
/** @return {import("@carbon/charts").interfaces.LineChartOptions} */
const DualQChartOptions = () => ({
    axes: {
        left: {
            title: "Độ mở cống (m)",
            mapsTo: "value",
            includeZero: false,
        },
        bottom: Axes.Time(),
        right: {
            title: "Lưu lượng qua cống (m3/s)",
            mapsTo: "Q",
            includeZero: false,
            correspondingDatasets: ["Lưu lượng"],
        },
    },
    curve: "curveMonotoneX",
    height: "330px",
    width: "100%",
    tooltip: Tooltip(),
    zoomBar: {
        top: {
            enabled: true,
            type: "slider_view",
        },
    },
    timeScale: TimeScale(),
})
/** @return {import("@carbon/charts").interfaces.LineChartOptions} */
const DualVolumeChartOptions = () => ({
    axes: {
        left: {
            title: "Mực nước (m)",
            mapsTo: "value",
            includeZero: false,
        },
        bottom: Axes.Time(),
        right: {
            title: "Thể tích (Triệu m3)",
            mapsTo: "volume",
            includeZero: false,
            correspondingDatasets: ["Thể tích"],
        },
    },
    curve: "curveMonotoneX",
    height: "330px",
    width: "100%",
    tooltip: Tooltip(),
    zoomBar: {
        top: {
            enabled: true,
            type: "slider_view",
        },
    },
    timeScale: TimeScale(),
})

/** @return {import("@carbon/charts").interfaces.LineChartOptions} */
const LineChartOptions = () => ({
    axes: {
        left: {
            mapsTo: "value",
            title: "Giá trị",
            scaleType: "linear",
            includeZero: false,
        },
        bottom: Axes.Time(),
    },
    curve: "curveMonotoneX",
    height: "280px",
    width: "100%",
    data: {

    },
    tooltip: Tooltip(),
    zoomBar: {
        top: {
            enabled: true,
            type: "slider_view",
        },
    },
    timeScale: TimeScale()
});

/** @return {import("@carbon/charts").interfaces.BarChartOptions} */
const DualBarChartOptions = () => ({
    axes: {
        left: {
            mapsTo: "value",
            title: "Giá trị",
        },
        bottom: {
            scaleType: "labels",
            mapsTo: "key",
            title : "Thời gian"
        }
    },
    bars: {
        maxWidth: 20,
    },
    tooltip: Tooltip(),
    width: "100%",
    height: "300px",
    timeScale: TimeScale()
});

/** @return {import("@carbon/charts").interfaces.BarChartOptions} */
const BarChartOptions = () => ({
    axes: {
        left: {
            mapsTo: "value",
            title: "Giá trị",
        },
        bottom: Axes.Time(),
    },
    bars: {
        maxWidth: 30,
    },
    tooltip: Tooltip(),
    width: "100%",
    height: "300px",
    timeScale: TimeScale()
});

const ChartOptions = { 
    line: LineChartOptions, 
    bar: BarChartOptions, 
    dualVolume: DualVolumeChartOptions, 
    dualQ: DualQChartOptions,
    dualRA: DualBarChartOptions 
};

function buildTimeSeries(records, metrics) {
    const timeseries = {};
    for (const r of records) {
        const date = r.t * 1000;
        for (const m of metrics) {
            const code = m.code;
            const value = r[code];
            if (isNaN(value)) {
                continue;
            }
            if (!timeseries[code]) {
                timeseries[code] = [];
            }
            timeseries[code].push([date, value]);
        }
    };
    return timeseries;
}

function aggregate(series, group_func) {
    const sums = new Map();
    for (const [date, value] of series) {
        const rounded = group_func(date);
        const current_value = sums.get(rounded) || 0;
        sums.set(rounded, current_value + value);
    }
    const res = [...sums.entries()].sort(
        (a, b) => a[0] < b[0] ? -1 : 1
    );
    return res;
}

function makeOption(g) {
    const { chart_type } = g;
    const title = `Dữ liệu ${g.label} (${g.unit})`;
    const option_func = ChartOptions[chart_type] || ChartOptions.line;
    const option = option_func();
    option.title = title;
    return option;
}

const ONE_HOUR = 3600e3;
const groupByHour = (t) => t - (t % ONE_HOUR);

export function BuildChartData(input, metrics = [], box_id, list_metric) {
    if(metrics.some(m => m.code === 'WAU')) {
        const volume = {
            id: '0',
            code: 'V',
            name: 'Thể tích'
        }
        metrics.push(volume)
        if(metrics.some(m => ['DR', 'DR1', 'DR2', 'DR3', 'DR4'].includes(m.code))) {
            const Q = {
                id: '1',
                code: 'Q',
                name: 'Lưu lượng'
            }
            metrics.push(Q)
        }
    }
    const metricCodeToName = new Map(list_metric.map(m => [m.code, m.name]));
    const timeseries = buildTimeSeries(input, metrics);

    const charts = [];
    METRIC_GROUPS.forEach((g) => {
        const { type = 'line', metric_codes } = g;
        const data = [];
        metric_codes.forEach(code => {
            if(code === 'VO' || code === 'TE') return;
            let series = timeseries[code] || [];
            if (code === 'RA') {
                series = aggregate(series, groupByHour);
            }
            const group = metricCodeToName.get(code) || code;
            for (const obj of series) {
                const [date, value] = obj;
                //thể tích
                if(code === 'V' && value > 0) {
                    let volume = {
                        group: "Thể tích",
                        date,
                        volume: value
                    }
                    data.push(volume);
                }else if(code === 'Q') {
                    let Q = {
                        group: "Lưu lượng",
                        date,
                        Q: value ? value : 0
                    }
                    data.push(Q);
                }else if(code === 'RA') { 
                    data.push({ group: 'RA', date, value });
                }else {
                    data.push({ group, date, value });
                }
            }
        });
        if (data.length < 1) {
            return;
        }
        const options = makeOption(g);
        charts.push({ type, data, options, group: g, box_id });
    });
    console.log('charts', charts);
    return charts;
}