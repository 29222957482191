import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Grid, Box, LinearProgress} from '@mui/material';
import { CAMERA_URL } from '../../Shared/Constances/const';
import { Http } from "../../Shared/Services/Http"

const DataCamera: React.FC<any> = (props) => {
    const { group, parentCallBack } = props;
    const [cameras, setCameras] = useState([]);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (group) {
            Http.get(`zone/box_group/get?id=${group.id}`)
                .then(res => {
                    res.data.cameras ? setCameras(res.data.cameras) : setCameras([])
                }
            ).catch(err => console.log(err))
        }
    }, [group])

    const handleFullScreen = (className) => {
        setIsFullScreen(!isFullScreen);
        if (!isFullScreen) {
            const img = document.getElementsByClassName(className);
            img[0].requestFullscreen();
        } else {
            document.exitFullscreen();
        }
    }

    const handleCameraStart = () => {
        setIsLoading(false);
    }

    const handleCloseCamera = () => {
        parentCallBack(true)
    } 

    return (
        <div className="DataCamera">
            <div className="title">
                <b className="cursor content">Camera</b>
                <div className="btn-close" onClick={() => handleCloseCamera()}>
                    <CloseIcon />
                </div>
            </div>
            <Grid container className="content-popup">
                {cameras.length !== 0 ? cameras.map((link, index) => {
                    return (
                        <Grid xs={6} item={true} key={index}>
                            <Box sx={{ width: "80%" }}>
                                { isLoading ?  <LinearProgress/> : null }
                            </Box> 
                            <img 
                                onLoad={() => handleCameraStart()}
                                key={`camera${index}`}
                                className={`camera${index}`}
                                alt="" 
                                width="97%" 
                                height="100%" 
                                src={`${CAMERA_URL}/play?link=${link}`}
                                onClick={() => handleFullScreen(`camera${index}`)}
                            /> 
                        </Grid> 
                    )
                }) : null } 
            </Grid>
        </div>
    );
}

export default DataCamera;