import React, { useEffect, useState, useMemo } from "react";
import Map from "../../../Modules/Map/Components/Map";
import Menu from "../../../Modules/Left/Menu/Menu";
import ToolBar from "../../../Modules/Right/ToolBar/ToolBar";
import { AppApi } from "./AppApi";
import { LatLngExpression } from "leaflet";
import DataTable from "../../../Modules/Data/DataTable";
import DataCamera from "../../../Modules/Data/DataCamera";
import DataChart from "../../../Modules/Data/DataChart";
import { BuildChartData } from "../../Charts/chart_data";
import { BuildChartDataRA } from "../../Charts/chart_RA_data";
import DraggableMe from "../../Draggable/Draggable";
import HorizonToolBar from "../../../Modules/Right/HorizonToolBar/HorizonToolBar";

const centerDefault: LatLngExpression = [11.080890, 107.163628];

const App: React.FC = () => {
  const [listGroup, setListGroup] = useState([]);
  const [center, setCenter] = useState(centerDefault);
  const [listZone, setListZone] = useState([]);
  const [zoneId, setZoneId] = useState(null);
  const [group, setGroup] = useState(null);
  const [box, setBox] = useState(null);
  const [loadingBox, setLoadingBox] = useState(false)
  const [zoom, setZoom] = useState(10);
  const [layer, setLayer] = useState('default');
  const [toggleTable, setToggleTable] = useState(false);
  const [toggleCamera, setToggleCamera] = useState(false);
  const [toggleChart, setToggleChart] = useState(false);
  const [typeMenu, setTypeMenu] = useState(1)
  const [checkedBoxMetrics, setCheckedBoxMetrics] = useState([''])
  const [dataTable, setDataTable] = useState([]);
  const [metrics, setMetric] = useState([]);
  const [listChart, setListChart] = useState([]);

  useMemo(() => {
    AppApi.ListZone().then(res => {
      setListZone(res)
    }).catch(err => console.log(err))

    AppApi.ListMetric().then(res => {
      setMetric(res)
    }).catch(err => console.log(err))
  }, [])

  useEffect(() => {
    if (!zoneId) return;
    AppApi.ListGroup(zoneId).then(res => {
      setListGroup(res)
    }).catch(err => console.log(err));
  }, [zoneId])

  useEffect(() => {
    if (group?.id) {
      AppApi.ListByGroup(group.id).then(res => {
        const listChart = res.map(r => {     
          const { records } = r;
          const data = BuildChartData(records, r.metrics, r.box.id, metrics)
          return data
        })
        const docs = BuildChartDataRA(listChart, group) 
        setListChart([...docs])
      });
    }
    if (group?.zoom > 0) {
      setZoom(group?.zoom)
      setCenter([group?.center.lat, group?.center.lng]);
    }
  }, [group, metrics]);

  useEffect(() => {
    if (box?.location) {
      setCenter([box?.location.lat, box?.location.lng]);
    }
  }, [box]);

  useMemo(() => {
    if (!box) return;
    if (box.id && metrics.length > 0) {
      setLoadingBox(true);
      AppApi.ListByBox(box.id).then(res => {
        setDataTable([...res.records.slice(0, 10)]); 
        setLoadingBox(false);
      }).catch(err => console.log(err))
    }
  }, [box, metrics]);

  const handleCloseCamera = (bool) => {
    if (bool) {
      setToggleCamera(!toggleCamera);
      window.stop();
    }
  }

  return (
    <div className="App">
      <Menu listGroup={listGroup} setCenter={setCenter} setZoom={setZoom} setBox={setBox} setGroup={setGroup} group={group} box={box}
        setToggleTable={setToggleTable} setToggleCamera={setToggleCamera} toggleCamera={toggleCamera} toggleTable={toggleTable}
        typeMenu={typeMenu} setTypeMenu={setTypeMenu} zoom={zoom} setDataTable={setDataTable} metrics={metrics} listZone={listZone}
        toggleChart={toggleChart} setToggleChart={setToggleChart} setZoneId={setZoneId} zoneId={zoneId} 
      />
      <Map typeMenu={typeMenu} setTypeMenu={setTypeMenu} listGroup={listGroup} center={center} zoom={zoom} setZoom={setZoom} layer={layer}
        box={box} setBox={setBox} group={group} setGroup={setGroup} checkedBoxMetrics={checkedBoxMetrics} setToggleTable={setToggleTable}
        setToggleChart={setToggleChart} toggleChart={toggleChart} dataTable={dataTable} 
      />
      <ToolBar layer={layer} setLayer={setLayer} checkedBoxMetrics={checkedBoxMetrics} setCheckedBoxMetrics={setCheckedBoxMetrics} />
      <HorizonToolBar zoom={zoom} setZoom={setZoom} />
      {toggleTable && <DraggableMe>
        <DataTable loadingBox={loadingBox} group={group} box={box} setBox={setBox} metrics={metrics} dataTable={dataTable} toggleTable={toggleTable} setToggleTable={setToggleTable} />
      </DraggableMe>}
      {toggleCamera && <DraggableMe>
        <DataCamera group={group} parentCallBack={handleCloseCamera} />
      </DraggableMe>}
      {toggleChart && <DraggableMe>
        <DataChart group={group} box={box} toggleChart={toggleChart} setToggleChart={setToggleChart} listChart={listChart} />
      </DraggableMe>}
    </div>
  );
};

export default App;
